import { store } from 'react-notifications-component';
import alertOptions from "../Alert/alertOptions";

export const checkFile = file => {
    const exts = ["jpg", "jpeg", "png"]
    const size = file.size
    const splitName = file.name.split(".")
    const ext = splitName[splitName.length - 1]
    if (size > (1048576 * 1)) { //1 MB
        store.addNotification({
            ...alertOptions,
            message: "Dimensione massima consentita 1 MB",
            type: "danger",
        });
        return false
    } else if (exts.indexOf(ext) === -1) {
        store.addNotification({
            ...alertOptions,
            message: "Formtao file non supportato (solo jpg o png)",
            type: "danger",
        });
        return false
    }
    return true
}