import React, { Component } from "react";
import ReactToPrint from "react-to-print";
import PageHelmet from "./../Components/Utils/PageHelmet";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { FiTrash, FiEdit2, FiPrinter, FiPlus } from "react-icons/fi";
import AnimateHeight from "react-animate-height";
import moment from "moment";
import "moment/locale/it";
import { fetchBusiness } from "../redux/actions/businessActions";
import { getMenuByBusiness } from "../redux/actions/menuActions";
import { connect } from "react-redux";
import Select from "react-select";
import selectOptions from "../Components/Utils/Form/selectOptions";
import "rc-time-picker/assets/index.css";
import {
  addOrdine,
  getOrdini,
  getOrdine,
  updateOrdine,
  deleteOrdine,
  resetForm,
} from "../redux/actions/ordineAcitions";
import DataTable from "react-data-table-component";
import memoize from "memoize-one";
import NoDataComponent from "../Components/Utils/Datatable/NoDataComponent";
import LoadingData from "../Components/Utils/Datatable/LoadingData";
import { store } from "react-notifications-component";
import alertOptions from "../Components/Alert/alertOptions";
import { confirmAlert } from "react-confirm-alert";
import ComandeTableDetails from "./../Components/Comande/ComandeTableDetails";
import ComandaPrint from "./ComandaPrint";

const columns = memoize((actionHandler) => [
  {
    name: "Cliente",
    selector: "cliente",
    sortable: false,
  },
  {
    name: "Orario",
    width: "75px",
    right: true,
    cell: (row) => (
      <div style={{ fontWeight: "bolder" }}>
        {moment(row.date).format("HH:mm")}
      </div>
    ),
  },
  {
    name: "Totale €",
    width: "100px",
    right: true,
    cell: (row) => (
      <div style={{ fontWeight: "bolder" }}>
        {row.ordinePietanza
          .reduce((a, b) => a + b.prezzo * b.numero + b.aggiunta, -row.sconto)
          .toFixed(2)}
      </div>
    ),
  },
  {
    sortable: false,
    width: "150px",
    right: true,
    cell: (row) => (
      <div className="d-flex">
        <button
          className="btn btn-circle btn-sm btn-table warning"
          onClick={() => actionHandler("update", row.id)}
        >
          <FiEdit2 />
        </button>
        <button
          className="btn btn-circle btn-sm ml-1 btn-table danger"
          onClick={() => actionHandler("delete", row.id)}
        >
          <FiTrash />
        </button>

        <button
          className="btn btn-circle btn-sm ml-1 btn-table secondary"
          onClick={() => actionHandler("print", row)}
        >
          <FiPrinter />
        </button>
      </div>
    ),
  },
]);

class Comande extends Component {
  constructor(props) {
    super(props);
    this.state = {
      today: moment(),
      formOpened: false,
      businessAll: {},
      business: null,
      pietanze: [],
      pietanza: null,
      numero: 1,
      cliente: "",
      note: "",
      ordineSel: null,
      aggiunta: 0,
      timeText: moment().format("HH:mm"),
      sconto: 0,
      modificaPietanza: false,
    };
  }

  componentDidMount() {
    this.getBusinessAll();
  }

  getBusinessAll = () => {
    this.props.fetchBusiness().then(() => {
      const business =
        this.props.businessAll.response.length > 0
          ? this.props.businessAll.response[0]
          : null;
      this.setState(
        {
          businessAll: this.props.businessAll,
          business: business,
        },
        () => this.setBusiness()
      );
    });
  };

  setBusiness = () => {
    const { business } = this.state;
    if (business) {
      this.fetchOrdini();
      this.resetForm();
      this.props.getMenuByBusiness(business.id);
    }
  };

  fetchOrdini = () => {
    const { business, today } = this.state;
    const data = {
      business: business.id,
      anno: today.year(),
      mese: today.month() + 1,
      giorno: today.date(),
    };
    this.props.getOrdini(data);
  };

  changeDay = (dir) => {
    const { today } = this.state;
    const newDate =
      dir === "-"
        ? moment(today).subtract(1, "days")
        : moment(today).add(1, "days");
    this.setState({ today: newDate }, () => this.fetchOrdini());
  };

  cambiaOrarioText = (data) => {
    const isNumber = new RegExp(/^\d+$/);
    const time = data.target.value;
    if (isNumber.test(time)) {
      this.setState({ timeText: time });
    }
  };

  onBlurTime = () => {
    const { timeText } = this.state;
    const isTime = new RegExp(/^([0-9]|0[0-9]|1[0-9]|2[0-3])[0-5][0-9]$/);
    if (isTime.test(timeText)) {
      const hour = timeText.slice(0, 2);
      const minute = timeText.slice(2);
      var newTime = moment();
      newTime.set({ hour: hour, minute: minute });
      this.setState({ timeText: newTime.format("HH:mm") });
    } else {
      this.setState({ timeText: "" });
    }
  };

  cambiaCliente = (cliente) => {
    this.setState({ cliente: cliente.target.value });
  };

  cambiaNote = (note) => {
    this.setState({ note: note.target.value });
  };

  cambiaAttivita = (attivita) => {
    this.setState({ business: attivita }, () => this.setBusiness());
  };

  cambiaNumero = (event) => {
    this.setState({
      numero: event.target.value !== "" ? event.target.value : 1,
    });
  };

  cambiaPrezzo = (data) => {
    const validNumber = new RegExp(/^\d*\.?\d*$/);
    if (validNumber.test(data.target.value)) {
      this.setState({ aggiunta: data.target.value });
    }
  };

  cambiaSconto = (data) => {
    const validNumber = new RegExp(/^\d*\.?\d*$/);
    if (validNumber.test(data.target.value)) {
      this.setState({ sconto: data.target.value });
    }
  };

  onBlurSconto = () => {
    if (this.state.sconto === "") {
      this.setState({ sconto: 0 });
    }
  };

  selezionaPietanza = (pietanza) => {
    this.setState({
      pietanza: pietanza,
    });
  };

  modificaPietanza = (index) => {
    this.setState({ modificaPietanza: true });
    const { pietanze } = this.state;
    const pietanza = pietanze[index];
    const pietanzaSelect = this.props.menu.response.pietanze.filter(
      (p) => p.id === pietanza.parent_id
    );
    console.log(pietanzaSelect);

    this.setState({
      pietanza: pietanzaSelect[0],
      aggiunta: pietanza.aggiunta,
      note: pietanza.note,
    });
  };

  aggiungiPietanza = () => {
    const { numero, pietanza, aggiunta, pietanze, note, modificaPietanza } =
      this.state;

    if (modificaPietanza) {
      this.setState(
        {
          pietanze: pietanze.map((item) => {
            var temp = Object.assign({}, item);
            console.log(pietanza);
            if (temp.parent_id === pietanza.id) {
              temp.aggiunta = temp.aggiunta + parseFloat(aggiunta);
              temp.note = note;
            }
            return temp;
          }),
        },
        () => {
          this.setState({
            pietanza: null,
            numero: 1,
            aggiunta: 0,
            note: "",
            modificaPietanza: false,
          });
        }
      );
    } else {
      if (numero !== "" && pietanza !== null) {
        pietanza.note = note;
        const exists = pietanze.filter(
          (value) => value.parent_id === pietanza.id
        );
        if (exists.length === 1) {
          this.setState(
            {
              pietanza: pietanza,
              pietanze: pietanze.map((item) => {
                var temp = Object.assign({}, item);

                if (temp.parent_id === pietanza.id) {
                  temp.numero += parseInt(numero);
                  temp.aggiunta = temp.aggiunta + parseFloat(aggiunta);
                  temp.note = temp.note + `\n${note}`;
                }
                return temp;
              }),
            },
            () => {
              this.setState({
                pietanza: null,
                numero: 1,
                aggiunta: 0,
                note: "",
              });
            }
          );
        } else {
          pietanza.numero = parseInt(numero);
          pietanza.aggiunta = parseFloat(aggiunta);
          pietanza.parent_id = pietanza.id;
          this.setState(
            {
              pietanza: pietanza,
              pietanze: [...pietanze, pietanza],
            },
            () =>
              this.setState({
                pietanza: null,
                numero: 1,
                aggiunta: 0,
                note: "",
              })
          );
        }
      }
    }
  };

  rimuoviPietanza = (id) => {
    confirmAlert({
      title: "Sei sicuro?",
      message: "Vuoi davvero elimiare l'elemento?",
      buttons: [
        {
          label: "Si",
          onClick: () => {
            this.setState({
              pietanze: this.state.pietanze.filter((item) => item.id !== id),
            });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  handleSubmit = () => {
    const { pietanze, cliente, business, today, timeText, sconto } = this.state;

    const { ordine } = this.props;

    const bodyFormData = new FormData();

    const newTime = timeText.split(":");

    const date = moment(today).set({ hour: newTime[0], minute: newTime[1] });
    const business_id = business.id;

    bodyFormData.append("cliente", cliente);
    bodyFormData.append("business", business_id);
    bodyFormData.append("date", date);
    bodyFormData.append("sconto", sconto);
    var i = 0;
    pietanze.forEach((elem) => {
      bodyFormData.append(`ordinePietanza[${i}].descrizione`, elem.descrizione);
      bodyFormData.append(`ordinePietanza[${i}].parent_id`, elem.parent_id);
      bodyFormData.append(`ordinePietanza[${i}].note`, elem.note);
      bodyFormData.append(`ordinePietanza[${i}].aggiunta`, elem.aggiunta);
      bodyFormData.append(
        `ordinePietanza[${i}].prezzo`,
        parseFloat(elem.prezzo)
      );
      bodyFormData.append(`ordinePietanza[${i}].numero`, elem.numero);
      i++;
    });
    if (!ordine || !ordine.hasOwnProperty("id")) {
      return this.props
        .addOrdine(bodyFormData)
        .then((res) => {
          this.printOrdine(res.value.data.response);
          this.closeForm();
          this.fetchOrdini();
          store.addNotification({
            ...alertOptions,
            message: "Operazione effettuata",
            type: "success",
          });
        })
        .catch(() => {
          this.closeForm();
          store.addNotification({
            ...alertOptions,
            message: "Impossibile completare l'operazione",
            type: "danger",
          });
        });
    }
    bodyFormData.append("id", ordine.id);
    return this.props
      .updateOrdine(bodyFormData)
      .then(() => {
        this.closeForm();
        this.fetchOrdini();
        store.addNotification({
          ...alertOptions,
          message: "Operazione effettuata",
          type: "success",
        });
      })
      .catch(() => {
        this.closeForm();
        store.addNotification({
          ...alertOptions,
          message: "Impossibile completare l'operazione",
          type: "danger",
        });
      });
  };

  getOrdine = (data) => {
    window.scrollTo(0, 0);
    this.props.getOrdine(data).then((res) => {
      if (!this.state.formOpened) {
        this.setState({ formOpened: true });
      }
      const ordine = res.value.data.response;
      this.setState({
        cliente: ordine.cliente,
        timeText: moment(ordine.date).format("HH:mm"),
        numero: 1,
        pietanze: ordine.ordinePietanza,
        sconto: ordine.sconto,
      });
    });
  };

  actionHandler = (action, data) => {
    switch (action) {
      case "update":
        this.getOrdine(data);
        break;
      case "delete":
        this.deleteOrdine(data);
        break;
      case "print":
        this.printOrdine(data);
        break;
      default:
        break;
    }
  };

  printOrdine = (data) => {
    this.setState({ ordineSel: data }, () => {
      document.getElementById("printordine").click();
    });
  };

  deleteOrdine = (data) => {
    confirmAlert({
      title: "Sei sicuro?",
      message: "Vuoi davvero elimiare l'elemento?",
      buttons: [
        {
          label: "Si",
          onClick: () => {
            this.props
              .deleteOrdine(data)
              .then(() => {
                if (this.state.formOpened) {
                  this.closeForm();
                }
                this.fetchOrdini();
                store.addNotification({
                  ...alertOptions,
                  message: "Operazione effettuata",
                  type: "success",
                });
              })
              .catch(() => {
                store.addNotification({
                  ...alertOptions,
                  message: "Impossibile completare l'operazione",
                  type: "danger",
                });
              });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  resetForm = () => {
    this.setState({
      // time: moment(),
      timeText: moment().format("HH:mm"),
      pietanze: [],
      pietanza: null,
      numero: 1,
      cliente: "",
      note: "",
      ordine: null,
      aggiunta: 0,
      sconto: 0,
      modificaPietanza: false,
    });
    this.props.resetForm();
  };

  openForm = () => {
    window.scrollTo(0, 0);
    this.setState({ formOpened: true });
    this.resetForm();
  };

  closeForm = () => {
    this.setState({ formOpened: false });
    this.resetForm();
  };

  elencoPietanze = (data) => {
    const output = data.reduce((acc, curr) => {
      curr.numero = 1;
      const exists = acc.find((o) => o.id === curr.id);
      curr.costo = curr.prezzo * curr.numero;
      if (exists) {
        exists.numero++;
        exists.costo = exists.prezzo * exists.numero;
      } else {
        acc.push(({ ...curr } = curr));
      }

      return acc;
    }, []);
    return output;
  };

  render() {
    const {
      today,
      formOpened,
      businessAll,
      business,
      pietanze,
      pietanza,
      numero,
      cliente,
      note,
      // time,
      ordineSel,
      aggiunta,
      timeText,
      sconto,
      modificaPietanza,
    } = this.state;

    const { menu, ordini, isLoadingOrdini, isLoadingOrdine, isUpdatingOrdine } =
      this.props;

    return (
      <>
        <ReactToPrint
          trigger={() => {
            return (
              <button
                className="btn btn-sm ft-btn-secondary mr-1 d-none"
                id="printordine"
              >
                <FiPrinter />
              </button>
            );
          }}
          content={() => this.componentRef}
          onAfterPrint={() => {
            this.setState({ ordineSel: null });
          }}
          pageStyle="@page { size: A6 portrait;}"
        />
        <div style={{ display: "none" }}>
          <ComandaPrint
            ordine={ordineSel}
            ref={(el) => (this.componentRef = el)}
          />
        </div>
        <PageHelmet pageTitle="Comande" />
        <div className="d-flex align-items-left mb-3">
          <div className="ft-page-title mr-3">
            <div>Comande</div>
          </div>
          <div style={{ marginTop: "-10px", width: "100%" }}>
            {businessAll.response && businessAll.response.length > 0 ? (
              <Select
                options={businessAll.response}
                styles={selectOptions}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.descrizione}
                noOptionsMessage={(value) => "Nessun risultato"}
                placeholder="Seleziona attività"
                defaultValue={business}
                onChange={(value) => this.cambiaAttivita(value)}
                isDisabled={formOpened}
              />
            ) : (
              <div>Nessuna attività inserita</div>
            )}
          </div>
        </div>
        <AnimateHeight duration={350} height={formOpened ? "auto" : 0}>
          <div className="form-comanda">
            <div className="row mb-4">
              <div className="col col-12">
                <div className="card ft-box">
                  <div className="card-body">
                    <div className="row pb-3">
                      <div className="col col-12">
                        <div className="d-flex justify-content-end">
                          <button
                            className="ft-btn-close-form btn btn-sm ft-btn-warning"
                            onClick={this.closeForm}
                          >
                            Chiudi
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-8">
                        <div className="form-group">
                          <label>Cliente</label>
                          <input
                            type="text"
                            autoFocus
                            className="ft-form-control form-control"
                            placeholder="Cliente"
                            value={cliente}
                            onChange={(value) => this.cambiaCliente(value)}
                          />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="form-group">
                          <label>Orario</label>
                          <br></br>
                          <input
                            type="text"
                            autoFocus
                            className="ft-form-control form-control"
                            placeholder="HHmm"
                            maxLength="5"
                            value={timeText}
                            onChange={(value) => this.cambiaOrarioText(value)}
                            onBlur={() => this.onBlurTime()}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        background: "#f2f3f8",
                        padding: "20px",
                        borderRadius: "12px",
                      }}
                    >
                      <div className="row">
                        <div className="col-md-2 col-12">
                          <div className="form-group">
                            <label>N.</label>
                            <input
                              disabled={modificaPietanza ? true : false}
                              type="number"
                              autoFocus
                              className="ft-form-control form-control"
                              placeholder="N."
                              value={numero}
                              min={1}
                              onChange={(event) => this.cambiaNumero(event)}
                            />
                          </div>
                        </div>
                        <div className="col-md-8 col-12">
                          <div className="form-group">
                            <label>Pietanza</label>
                            {menu.response &&
                            menu.response.pietanze.length > 0 ? (
                              <Select
                                isDisabled={modificaPietanza ? true : false}
                                options={menu.response.pietanze.filter(
                                  (pietanza) => pietanza.id > 0
                                )}
                                styles={selectOptions}
                                getOptionValue={(option) => option.id}
                                getOptionLabel={(option) =>
                                  option.descrizione +
                                  ` (${option.prezzo.toFixed(2)})`
                                }
                                noOptionsMessage={(value) => "Nessun risultato"}
                                placeholder="Seleziona pietanza"
                                value={pietanza}
                                onChange={(value) =>
                                  this.selezionaPietanza(value)
                                }
                              />
                            ) : (
                              <div>Nessuna pietanza inserita</div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-2 col-12">
                          <div className="form-group">
                            <label>Aggiunta</label>
                            <input
                              type="text"
                              className="ft-form-control form-control"
                              placeholder="Aggiunta"
                              value={aggiunta}
                              onChange={(value) => this.cambiaPrezzo(value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-10 col-12">
                          <div className="form-group">
                            <label>Note</label>
                            <textarea
                              rows="5"
                              style={{ resize: "none" }}
                              resize="none"
                              className="ft-form-control form-control"
                              placeholder="Note"
                              value={note}
                              onChange={(value) => this.cambiaNote(value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-2 col-12">
                          <button
                            className={`btn btn-sm ${
                              modificaPietanza
                                ? "ft-btn-warning"
                                : "ft-btn-primary"
                            } form-control `}
                            style={{ marginTop: "30px" }}
                            disabled={!pietanza}
                            onClick={() => this.aggiungiPietanza()}
                          >
                            {modificaPietanza ? "Modifica" : "Aggiungi"}
                          </button>
                        </div>
                      </div>
                      <div style={{ fontSize: "11px" }} className="mt-3">
                        <div className="row">
                          <div className="col col-md-8 col-7">
                            <b>Descrizione</b>
                          </div>
                          <div
                            className="col col-md-3 col-3 text-right"
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            <b>Prezzo &euro;</b>
                          </div>
                          <div className="col col-md-1 col-2"> </div>
                        </div>
                        <div className="mt-2">
                          {pietanze.length === 0 ? (
                            <div className="row">
                              <div className="col col-12">
                                Nessuna pietanza inserita
                              </div>
                            </div>
                          ) : (
                            <div>
                              {pietanze.map((pietanza, index) => (
                                <div
                                  className="row mt-2 align-items-center"
                                  key={index}
                                  style={{
                                    borderBottom: "1px solid #c1c5d9",
                                    padding: "15px 0",
                                  }}
                                >
                                  <div
                                    className="col col-md-8 col-7"
                                    style={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {pietanza.numero} x {pietanza.descrizione}
                                    <br></br>
                                    <span
                                      style={{
                                        fontSize: "10px",
                                        whiteSpace: "pre-line",
                                      }}
                                    >
                                      {pietanza.note}
                                    </span>
                                  </div>
                                  <div className="col col-md-3 col-3 text-right">
                                    {(
                                      pietanza.prezzo * pietanza.numero +
                                      pietanza.aggiunta
                                    ).toFixed(2)}
                                  </div>
                                  <div className="col col-md-1 col-2 d-flex justify-content-end">
                                    <button
                                      className="btn btn-circle btn-xs btn-table warning"
                                      style={{ marginTop: "-2px" }}
                                      onClick={() =>
                                        this.modificaPietanza(index)
                                      }
                                    >
                                      <FiEdit2 />
                                    </button>
                                    <button
                                      className="btn btn-circle btn-xs btn-table danger"
                                      style={{ marginTop: "-2px" }}
                                      onClick={() =>
                                        this.rimuoviPietanza(pietanza.id)
                                      }
                                    >
                                      <FiTrash />
                                    </button>
                                  </div>
                                </div>
                              ))}
                              <div
                                className="row mt-3"
                                style={{ fontWeight: "600" }}
                              >
                                <div className="col col-md-8 col-7">Totale</div>
                                <div className="col col-md-3 col-3 text-right">
                                  {pietanze
                                    .reduce(
                                      (a, b) =>
                                        a + b.prezzo * b.numero + b.aggiunta,
                                      0
                                    )
                                    .toFixed(2)}
                                </div>
                              </div>
                              <div
                                className="row mt-3"
                                style={{ fontWeight: "600" }}
                              >
                                <div className="col col-md-8 col-7">Sconto</div>
                                <div className="col col-md-3 col-3 text-right">
                                  {parseFloat(sconto).toFixed(2)}
                                </div>
                              </div>
                              <div
                                className="row mt-3"
                                style={{ fontWeight: "600" }}
                              >
                                <div className="col col-md-8 col-7">
                                  Totale scontato
                                </div>
                                <div className="col col-md-3 col-3 text-right">
                                  {pietanze
                                    .reduce(
                                      (a, b) =>
                                        a + b.prezzo * b.numero + b.aggiunta,
                                      -parseFloat(sconto)
                                    )
                                    .toFixed(2)}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row mb-2 mt-4 d-flex justify-content-end">
                      <div>
                        <div className="form-group d-flex">
                          <div
                            style={{ marginTop: "10px", marginRight: "5px" }}
                          >
                            Sconto
                          </div>
                          <input
                            style={{ textAlign: "right", maxWidth: "100px" }}
                            type="text"
                            className="ft-form-control form-control"
                            placeholder="Sconto"
                            value={sconto}
                            onChange={(value) => this.cambiaSconto(value)}
                            onBlur={() => this.onBlurSconto()}
                          />
                        </div>
                      </div>
                      <div style={{ margin: "5px 15px 0 27px" }}>
                        <button
                          type="button"
                          className="ft-btn-close-form btn btn-sm ft-btn-secondary"
                          disabled={
                            cliente === "" ||
                            pietanze.length === 0 ||
                            timeText === ""
                          }
                          onClick={() => this.handleSubmit()}
                        >
                          Salva
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AnimateHeight>
        <div className="row mb-4">
          <div className="col col-12">
            <div className="card ft-box">
              <div className="card-body">
                {(isUpdatingOrdine || isLoadingOrdine) && <LoadingData />}
                <div className="row mb-3">
                  <div className="col col-12">
                    <div className="d-flex justify-content-end">
                      <button
                        className="ft-btn-add-element btn ft-btn-primary btn-circle"
                        onClick={this.openForm}
                        disabled={formOpened}
                      >
                        <FiPlus />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center pb-4">
                  <div className="prev-day">
                    <button
                      className="btn btn-sm ft-btn-secondary"
                      onClick={() => this.changeDay("-")}
                      disabled={formOpened}
                    >
                      <FaAngleLeft />
                    </button>
                  </div>
                  <div className="title-date">
                    {today.format("DD MMMM YYYY")}
                  </div>
                  <div className="next-day">
                    <button
                      className="btn btn-sm ft-btn-secondary"
                      onClick={() => this.changeDay("+")}
                      disabled={formOpened}
                    >
                      <FaAngleRight />
                    </button>
                  </div>
                </div>
                {isLoadingOrdini && <LoadingData />}
                <DataTable
                  className="ft-table"
                  data={ordini.response}
                  columns={columns(this.actionHandler)}
                  noHeader
                  progressPending={false}
                  noDataComponent={<NoDataComponent />}
                  expandableRows
                  expandableRowsComponent={<ComandeTableDetails />}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoadingBusinessAll: state.business.isLoadingBusinessAll,
    businessAll: state.business.businessAll,
    menu: state.menu.menu,
    isLoadingMenu: state.menu.isLoadingMenu,
    isUpdatingOrdine: state.ordine.isUpdatingOrdine,
    isLoadingOrdini: state.ordine.isLoadingOrdini,
    ordini: state.ordine.ordini,
    isLoadingOrdine: state.ordine.isLoadingOrdine,
    ordine: state.ordine.ordine,
  };
};

export default connect(mapStateToProps, {
  fetchBusiness,
  getMenuByBusiness,
  getOrdini,
  resetForm,
  addOrdine,
  getOrdine,
  updateOrdine,
  deleteOrdine,
})(Comande);
