const alertOptions = {
    title: "",
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "bounceInRight"],
    animationOut: ["animated", "bounceOutRight"],
    dismiss: {
        duration: 2000,
        onScreen: true,
        showIcon: true
    }
}

export default alertOptions