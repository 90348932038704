import React from "react";
import { GiWheat } from "react-icons/gi";
import { FastFoodOutline } from "react-ionicons-v5";
import { BiDish } from "react-icons/bi";
import { GrRestaurant } from "react-icons/gr";
import { HiPencil } from "react-icons/hi";

export const menuItems = [
    {
        label: "Gestione",
        items: [
            {
                label: "Attività",
                path: "attivita",
                icon: <GrRestaurant />
            },
            {
                label: "Comande",
                path: "comande",
                icon: <HiPencil />
            }
        ]
    },
    {
        label: "Impostazioni",
        items: [
            {
                label: "Materie prime",
                path: "materie-prime",
                icon: <GiWheat />
            },
            {
                label: "Tipologie",
                path: "tipologie",
                icon: <FastFoodOutline />
            }
            ,
            {
                label: "Pietanze",
                path: "pietanze",
                icon: <BiDish />
            }
        ]
    }
    // ,
    // {
    //     label: "Playground",
    //     items: [
    //         {
    //             label: "Upload",
    //             path: "upload",
    //             icon: <GiCloudUpload />
    //         }
    //     ]
    // }
    /* {
         label: "Eventi",
         items: [
             {
                 label: "Calendario",
                 path: "calendario-eventi",
                 icon: <FiCalendar/>
             },
             {
                 label: "Lista",
                 path: "lista-eventi",
                 icon: <FiList/>
             },
         ]
     },
     {
         label: "Impostazioni",
         items: [
             {
                 label: "Tipologie evento",
                 path: "tipologie-evento",
                 icon: <FaGlassCheers/>
             },
             {
                 label: "Servizi extra",
                 path: "2",
                 icon: <FiGift/>
             },
             {
                 label: "Menu",
                 path: "3",
                 icon: <MdRestaurant/>
             },
             {
                 label: "Sale",
                 path: "4",
                 icon: <GiRoundTable/>
             },
         ]
     }*/
]