import spring from "../../axios/axios"

const APIGETBYBUSINESS = "getMenuByBusiness"
const APIADD = "addPietanzeMenu"
const APIGETPIETANZA = "getPietanzaMenu"
const APIDELETE = "deletePietanzaMenu"

export const getMenuByBusiness = data => {
    return async dispatch => {
        return dispatch({
            type: 'GET_MENU_BY_BUSINESS',
            payload: spring.get(`${APIGETBYBUSINESS}/${data}`)
        })
    }
}

export const addPietanzeMenu = data => {
    return async dispatch => {
        return dispatch({
            type: 'ADD_PIETANZA_MENU',
            payload: spring.post(APIADD, {}, {params: data})
        })
    }
}

export const getPietanzaByMenuAndPietanza = data => {
    return async dispatch => {
        return dispatch({
            type: 'GET_PIETANZA_MENU',
            payload: spring.post(APIGETPIETANZA, {}, {params: data})
        })
    }
}

export const deletePietanzeFromMenu = data => {
    return async dispatch => {
        return dispatch({
            type: 'DELETE_PIETANZA_MENU',
            payload: spring.post(APIDELETE, {}, {params: data})
        })
    }
}

export const resetForm = () => {
    return async dispatch => {
        return dispatch({
            type: 'RESET_FORM_MENU'
        })
    }
}


