import React from "react";
import { Field, reduxForm } from "redux-form";
import validate from "./validatePietanze";
import AsyncSelect from "react-select/async";
import selectOptions from "../Utils/Form/selectOptions";
import { FiX } from "react-icons/fi";

const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <div className="form-group">
    <label>{label}</label>
    <input
      {...input}
      type={type}
      className={`ft-form-control form-control ${
        touched && error && "ft-input-error"
      }`}
      placeholder={label}
    />
    {touched && error && (
      <small className="form-text error">{error.message}</small>
    )}
  </div>
);

const renderTextArea = ({ input, label, type, meta: { touched, error } }) => (
  <div className="form-group">
    <label>{label}</label>
    <textarea
      {...input}
      style={{ resize: "none" }}
      rows="4"
      cols="50"
      className={`ft-form-control form-control ${
        touched && error && "ft-input-error"
      }`}
      placeholder={label}
    />
    {touched && error && (
      <small className="form-text error">{error.message}</small>
    )}
  </div>
);

const ReduxFormSelectAsync = (props) => {
  const {
    input,
    label,
    render,
    getOptionValue,
    getOptionLabel,
    addMateriaPrima,
  } = props;
  const { touched, error } = props.meta;

  return (
    <div className="mb-3">
      <label>{label}</label>
      <AsyncSelect
        {...input}
        classNamePrefix={touched && error && "hasError"}
        onChange={(value) => addMateriaPrima(input, value)}
        isClearable
        cacheOptions
        onBlur={() => input.onBlur(input.value)}
        loadOptions={render}
        styles={selectOptions}
        placeholder={label}
        noOptionsMessage={(value) =>
          value.inputValue.length < 3
            ? "Inserisci almeno 3 caratteri"
            : "Nessun risultato"
        }
        loadingMessage={() => "Cerco..."}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
      />
      {touched && error && (
        <small className="form-text error">{error.message}</small>
      )}
    </div>
  );
};

const PietanzeForm = (props) => {
  // pristine, submitting,

  const {
    handleSubmit,
    selectOptions,
    renderMateriePrime,
    addMateriaPrima,
    materiePrime,
    deleteMateriaPrima,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col col-12">
          <Field
            name="descrizione"
            type="text"
            component={renderField}
            label="Descrizione"
          />
        </div>
      </div>
      <div className="row">
        <div className="col col-12">
          <Field
            name="info"
            type="text"
            component={renderTextArea}
            label="Info"
          />
        </div>
      </div>
      <div className="row">
        <div className="col col-12">
          <Field
            name="materiaprima"
            label="Materia prima"
            component={ReduxFormSelectAsync}
            options={selectOptions}
            render={renderMateriePrime}
            addMateriaPrima={addMateriaPrima}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.descrizione}
          />
        </div>
      </div>
      <div className="row">
        <div className="col col-12" id="mtpanel">
          <div className="d-flex flex-wrap materie_prime_container">
            {materiePrime.length === 0 ? (
              <div className="mt-2">Nessuna materia prima inserita</div>
            ) : (
              materiePrime.map((materiaprima, index) => {
                return (
                  <div key={index} className="mr-2 mt-2 materie-prime">
                    <div
                      className="delete-container"
                      onClick={() => deleteMateriaPrima(materiaprima)}
                    >
                      <FiX />
                    </div>
                    {materiaprima.descrizione}
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col col-12 d-flex justify-content-end">
          <button
            type="submit"
            className="ft-btn-close-form btn btn-sm ft-btn-secondary"
          >
            Salva
          </button>
          {/* disabled={pristine || submitting} */}
        </div>
      </div>
    </form>
  );
};

export default reduxForm({
  form: "pietanze-form",
  validate,
  enableReinitialize: true,
  destroyOnUnmount: false,
})(PietanzeForm);
