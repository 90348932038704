const validate = (values) => {
    const errors = {};
    if (!values.descrizione) {
        errors.descrizione = {
            message: 'Descrizione richiesta'
        }
    }
    if (!values.costo) {
        errors.costo = {
            message: 'Costo richiesto'
        }
    }else if (!/^[0-9]+(\.[0-9]{1,3})?$/.test(values.costo)) {
        errors.costo = {
            message: 'Costo non valido'
        }
    }if (!values.prezzoVendita) {
        errors.prezzoVendita = {
            message: 'Prezzo vendita richiesto'
        }
    }else if (!/^[0-9]+(\.[0-9]{1,3})?$/.test(values.prezzoVendita)) {
        errors.prezzoVendita = {
            message: 'Prezzo vendita non valido'
        }
    }
    return errors;
}

export default validate;