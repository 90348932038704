export const toggleCollapseSidebar = () => {
    return async dispatch => {
        return dispatch({
            type: 'TOGGLE_SIDEBAR_COLLAPSE',
        })
    }
}

export const toggleSidebarMobile = () => {
    return async dispatch => {
        return dispatch({
            type: 'TOGGLE_SIDEBAR_MOBILE',
        })
    }
}

export const toggleHeaderMobile = () => {
    return async dispatch => {
        return dispatch({
            type: 'TOGGLE_HEADER_MOBILE',
        })
    }
}

export const togglePanelPietanze = () => {
    return async dispatch => {
        return dispatch({
            type: 'TOGGLE_PANEL_PIETANZE',
        })
    }
}