import React from 'react';
import { Field, reduxForm } from 'redux-form';
import validate from "./validateTipologie";

const renderField = ({
    input,
    label,
    type,
    meta: { touched, error }
}) => (
    <div className="form-group">
        <label>{label}</label>
        <input {...input} type={type} className={`ft-form-control form-control ${touched && error && 'ft-input-error'}`} placeholder={label} />
        {
            touched && error &&
            <small className="form-text error">{error.message}</small>
        }
    </div>
)

const renderTextArea = ({
    input,
    label,
    type,
    meta: { touched, error }
}) => (
    <div className="form-group">
        <label>{label}</label>
        <textarea id="note"  {...input} style={{ resize: "none" }} className={`ft-form-control form-control ${touched && error && 'ft-input-error'}`} placeholder={label} rows="6" cols="30" />
        {
            touched && error &&
            <small className="form-text error">{error.message}</small>
        }
    </div>
)

const TipologieForm = props => {

    const { handleSubmit, pristine, submitting } = props;


    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col col-12">
                    <Field name="descrizione" type="text" component={renderField} label="Descrizione" />
                </div>
            </div>
            <div className="row">
                <div className="col col-12">
                    <Field name="note" component={renderTextArea} label="Note" />
                </div>
            </div>
            <div className="row mb-2">
                <div className="col col-12 d-flex justify-content-end">
                    <button type="submit" className="ft-btn-close-form btn btn-sm ft-btn-secondary" disabled={pristine || submitting}>Salva</button>
                </div>
            </div>
        </form>
    )
}

export default reduxForm({
    form: 'tipologie-form',
    validate,
    enableReinitialize: true,
    destroyOnUnmount: false,
})(TipologieForm)