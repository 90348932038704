import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import rootReducer from './reducers'
import promise from "redux-promise-middleware"

let middleware = [promise, thunkMiddleware]

if (process.env.NODE_ENV !== 'production') {
    middleware = [...middleware, createLogger()]
}

const store = createStore(rootReducer, applyMiddleware(...middleware))

export default store