import React, { Component } from 'react'
import { FiPower } from "react-icons/fi";
import { connect } from "react-redux";
import HeaderMobile from './HeaderMobile';


class Header extends Component {


    logoutuser = () => {
        localStorage.removeItem("jwt");
        window.location.reload();
    }

    render() {

        const { username } = this.props;

        return (
            <>
                <HeaderMobile />
                <div className="ft-header">
                    <div>Ciao, <b>{username}</b></div>
                    <div>
                        <button className="btn  ft-btn-primary btn-circle" onClick={this.logoutuser}><FiPower /></button>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        username: state.user.user.username
    }
}

export default connect(mapStateToProps)(Header);
