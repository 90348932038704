import spring from "../../axios/axios"

const APITABLE = "materiePrimePaging"
const APIGETID = "materiaPrima"
const APIUPDATE = "updateMateriaPrima"
const APIADD = "addMateriaPrima"
const APIDELETE = "deleteMateriaPrima"
const APIGET = "materiePrime"
const APIFIND = "findMateriaPrimaByDescrizione"

export const fetchMateriePrime = (sortBy = "descrizione", sortDirection = "ASC", descrizione = "", pageNumber = "0", pageSize = "10") => {
    return async dispatch => {
        return dispatch({
            type: 'FETCH_MATERIE_PRIME',
            payload: spring.get(`${APITABLE}?sortBy=${sortBy}&sortDirection=${sortDirection}&descrizione=${descrizione}&pageNumber=${pageNumber}&pageSize=${pageSize}`)
        })
    }
}

export const getMateriePrime = () => {
    return async dispatch => {
        return dispatch({
            type: 'FETCH_MATERIE_PRIME',
            payload: spring.get(APIGET)
        })
    }
}

export const getMateriePrimeByDescrizione = data => {
    return async dispatch => {
        return dispatch({
            type: 'FETCH_MATERIE_PRIME',
            payload: spring.get(`${APIFIND}?descrizione=${data}`)
        })
    }
}

export const getMateriaPrima = data => {
    return async dispatch => {
        return dispatch({
            type: 'GET_MATERIA_PRIMA',
            payload: spring.get(`${APIGETID}/${data}`)
        })
    }
}


export const addMateriaPrima = data => {
    return async dispatch => {
        return dispatch({
            type: 'ADD_MATERIA_PRIMA',
            payload: spring.post(APIADD, {}, {params:data})
        })
    }
}

export const updateMateriaPrima = data => {
    return async dispatch => {
        return dispatch({
            type: 'UPDATE_MATERIA_PRIMA',
            payload: spring.put(APIUPDATE, {},{params:data})
        })
    }
}

export const deleteMateriaPrima = data => {
    return async dispatch => {
        return dispatch({
            type: 'DELETE_MATERIA_PRIMA',
            payload: spring.delete(`${APIDELETE}/${data}`)
        })
    }
}


export const resetForm = () => {
    return async dispatch => {
        return dispatch({
            type: 'RESET_FORM_MATERIA_PRIMA'
        })
    }
}