const validate = (values) => {
    const errors = {};
    if (!values.descrizione) {
        errors.descrizione = {
            message: 'Descrizione richiesta'
        }
    }
    return errors;
}

export default validate;