import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { userLogin } from "../redux/actions/userActions";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(evt) {
    const value = evt.target.value;
    this.setState({
      ...this.state,
      [evt.target.name]: value,
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.props
      .userLogin({
        username: this.state.username,
        password: this.state.password,
      })
      .then(() => {})
      .catch(() => {});
  };

  render() {
    const { isAuth, location, message, isLoading } = this.props;

    if (isLoading && localStorage.getItem("jwt")) {
      return <></>;
    }

    if (isAuth) {
      const { from } = location.state || { from: { pathname: "/" } };
      return <Redirect to={from} />;
    }
    return (
      <div className="login-container">
        <div className="left-side">
          <div>
            QR <div>Menu</div>
          </div>
        </div>
        <div className="right-side">
          <form onSubmit={this.handleSubmit}>
            <h5>Login</h5>
            <label htmlFor="username">Username</label>
            <input
              type="text"
              name="username"
              className="ft-form-control form-control"
              value={this.state.username}
              onChange={this.handleChange}
            />
            <label htmlFor="password">Password</label>
            <input
              type="password"
              name="password"
              className="ft-form-control form-control"
              value={this.state.password}
              onChange={this.handleChange}
            />
            <input
              className="btn ft-btn-secondary"
              type="submit"
              value="LOGIN"
            />
            <div>{message !== "Utente non loggato" ? message : ""}</div>
          </form>
          <img
            alt="logo"
            src={require("../../public/assets/images/qrmenudark.svg")}
            style={{ width: "150px", marginTop: "-15px" }}
          />
          <div className="footer">2021 &copy; Softplus S.r.l.</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuth: state.user.isAuth,
    message: state.user.message,
    user: state.user.user,
    isLoading: state.user.isLoading,
  };
};

export default connect(mapStateToProps, { userLogin })(Login);
