const initialState = {
    tipologie:{},
    isLoadingTipologie:false,
    isUpdatingTipologie: false,
    tipologia: {},
    isLoadingTipologia:false,
    isUpdatingTipologia:false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_TIPOLOGIE_PENDING': {
            return {
                ...state,
                isLoadingTipologie: true,
            }
        }
        case 'FETCH_TIPOLOGIE_FULFILLED': {
            return {
                ...state,
                isLoadingTipologie: false,
                tipologie: action.payload.data,
            }
        }
        case 'UPDATE_TIPOLOGIE_PENDING': {
            return {
                ...state,
                isUpdatingTipologie: true,
            }
        }
        case 'UPDATE_TIPOLOGIE_FULFILLED': {
            return {
                ...state,
                isUpdatingTipologie: false,
                tipologie: action.payload.data,
            }
        }
        case 'GET_TIPOLOGIA_PENDING': {
            return {
                ...state,
                isLoadingTipologia: true,
            }
        }
        case 'GET_TIPOLOGIA_FULFILLED': {
            return {
                ...state,
                isLoadingTipologia: false,
                tipologia: action.payload.data.response,
            }
        }
        case 'ADD_TIPOLOGIA_PENDING': {
            return {
                ...state,
                isUpdatingTipologia: true
            }
        }
        case 'ADD_TIPOLOGIA_FULFILLED': {
            return {
                ...state,
                isUpdatingTipologia: false,
                tipologie: {...state.tipologie, response:[...state.tipologie.response, action.payload.data.response]}
            }
        }
        case 'ADD_TIPOLOGIA_REJECTED': {
            return {
                ...state,
                isUpdatingTipologia: false,
                //GESTIRE,
            }
        }
        case 'UPDATE_TIPOLOGIA_PENDING': {
            return {
                ...state,
                isUpdatingTipologia: true
            }
        }
        case 'UPDATE_TIPOLOGIA_FULFILLED': {
            return {
                ...state,
                isUpdatingTipologia: false,
                tipologie: {...state.tipologie, response:state.tipologie.response
                    .map(tipologia => tipologia.id !== action.payload.data.response.id ? tipologia : action.payload.data.response)}
            }
        }
        case 'UPDATE_TIPOLOGIA_REJECTED': {
            return {
                ...state,
                isUpdatingTipologia: false,
                //GESTIRE,
            }
        }
        case 'DELETE_TIPOLOGIA_PENDING': {
            return {
                ...state,
                isUpdatingTipologia:true
            }
        }
        case 'DELETE_TIPOLOGIA_FULFILLED': {
            return {
                ...state,
                isUpdatingTipologia:false,
                tipologie: {...state.tipologie, response:state.tipologie.response.filter(tipologia => tipologia.id !== action.payload.data.response.id)}
            }
        }
        case 'DELETE_TIPOLOGIA_REJECTED': {
            return {
                ...state,
                isUpdatingTipologia:false
                //GESTIRE
            }
        }
        case 'RESET_FORM_TIPOLOGIA': {
            return {
                ...state,
                tipologia: {},
                isLoadingTipologia:false,
                isUpdatingTipologia:false,
            }
        }
        default:
            return state;
    }
}

export default reducer;