import React, { Component } from 'react';
import { connect } from "react-redux";
import LogoContainer from '../Sidebar/LogoContainer';
import { FiMenu, FiMoreVertical } from "react-icons/fi";

import { toggleSidebarMobile, toggleHeaderMobile } from "../../../redux/actions/sidebar_actions";

class HeaderMobile extends Component {

    onShowSidebar = () => {
        this.props.toggleSidebarMobile();
    }

    onShowHeader = () => {
        this.props.toggleHeaderMobile();
    }

    render() {
        return (
            <div className="ft-header-mobile">
                <LogoContainer />
                <div className="ft-actions-container">
                    <div className="ft-show-sidebar" onClick={this.onShowSidebar}>
                        <FiMenu />
                    </div>
                    <div className="ft-show-header" onClick={this.onShowHeader}>
                        <FiMoreVertical />
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(null, { toggleSidebarMobile, toggleHeaderMobile })(HeaderMobile);
