import spring from "../../axios/axios";

const APIGROUPED = "pietanzeByTipologia";
const APIPAGING = "pietanzePaging";
const APIADD = "addPietanza";
const APIUPDATE = "updatePietanza";
const APIGETID = "pietanza";
const APIDELETE = "deletePietanza";
const APIFIND = "findPietanzeByDescrizione";
const APIFINDBYBUSINESSANDTIPO = "getPietanzaByBusinessAndTipologia";
const APISETIMAGE = "setImageToPietanza";
const APIDELETEIMAGE = "deleteImageToPietanza";

export const fetchPietanzePaging = (
  tipologia,
  sortBy = "descrizione",
  sortDirection = "ASC",
  descrizione = "",
  pageNumber = "0",
  pageSize = "10"
) => {
  return async (dispatch) => {
    return dispatch({
      type: "FETCH_PIETANZE_PAGING",
      payload: spring.get(
        `${APIPAGING}/${tipologia}?sortBy=${sortBy}&sortDirection=${sortDirection}&descrizione=${descrizione}&pageNumber=${pageNumber}&pageSize=${pageSize}`
      ),
    });
  };
};

export const fetchPietanzeByTipologia = (data) => {
  return async (dispatch) => {
    return dispatch({
      type: "FETCH_PIETANZE_BY_TIPOLOGIA",
      payload: spring.get(`${APIGROUPED}/${data}`),
    });
  };
};

export const fetchPietanzeByBusinessAndTipologia = (data) => {
  return async (dispatch) => {
    return dispatch({
      type: "FETCH_PIETANZE_BY_BUSINESS_AND_BY_TIPOLOGIA",
      payload: spring.post(APIFINDBYBUSINESSANDTIPO, {}, { params: data }),
    });
  };
};

export const getPietanzeByDescrizione = (data) => {
  return async (dispatch) => {
    return dispatch({
      type: "FETCH_PIETANZE_BY_TIPOLOGIA",
      payload: spring.get(`${APIFIND}?descrizione=${data}`),
    });
  };
};

export const getPietanza = (data) => {
  return async (dispatch) => {
    return dispatch({
      type: "GET_PIETANZA",
      payload: spring.get(`${APIGETID}/${data}`),
    });
  };
};

export const addPietanza = (data) => {
  return async (dispatch) => {
    return dispatch({
      type: "ADD_PIETANZA",
      payload: spring.post(APIADD, data),
    });
  };
};

export const updatePietanza = (data) => {
  return async (dispatch) => {
    return dispatch({
      type: "UPDATE_PIETANZA",
      payload: spring.put(APIUPDATE, data),
    });
  };
};

export const setPietanzaImage = (data) => {
  return async (dispatch) => {
    return dispatch({
      type: "UPDATE_PIETANZA",
      payload: spring.post(APISETIMAGE, data),
    });
  };
};

export const deletePietanzaImage = (data) => {
  return async (dispatch) => {
    return dispatch({
      type: "UPDATE_PIETANZA",
      payload: spring.get(`${APIDELETEIMAGE}/${data}`),
    });
  };
};

export const deletePietanza = (data) => {
  return async (dispatch) => {
    return dispatch({
      type: "DELETE_PIETANZA",
      payload: spring.delete(`${APIDELETE}/${data}`),
    });
  };
};

export const resetForm = () => {
  return async (dispatch) => {
    return dispatch({
      type: "RESET_FORM_PIETANZA",
    });
  };
};

export const resetPietanzeTemp = () => {
  return async (dispatch) => {
    return dispatch({
      type: "RESET_PIETANZE_TEMP",
    });
  };
};
