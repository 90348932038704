import React, { Component } from "react";
import PageHelmet from "../Components/Utils/PageHelmet";
import { connect } from "react-redux";
import {
  fetchBusiness,
  getBusiness,
  addBusiness,
  deleteBusiness,
  updateBusiness,
  resetForm,
  setBusinessImage,
  deleteBusinessImage,
} from "../redux/actions/businessActions";
import LoadingData from "../Components/Utils/Datatable/LoadingData";
import { FiPlus, FiTrash, FiEdit2 } from "react-icons/fi";
import { GrClose } from "react-icons/gr";
import { FaTrash } from "react-icons/fa";
import isEqual from "react-fast-compare";
import { store } from "react-notifications-component";
import alertOptions from "../Components/Alert/alertOptions";
import { confirmAlert } from "react-confirm-alert";
import AnimateHeight from "react-animate-height";
import BusinessForm from "../Components/Business/BusinessForm";
import ImageUploader from "react-images-upload";
import { checkFile } from "../Components/Business/checkFile";
import { server } from "../config/server";

class Attivita extends Component {
  constructor(props) {
    super(props);
    this.state = {
      businessAll: {},
      formOpened: false,
      overlayOpened: false,
      businessSlected: null,
    };

    this.businessForm = React.createRef();
    this.blurActiveElement = this.blurActiveElement.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let state = {};
    if (!isEqual(nextProps.businessAll, prevState.businessAll)) {
      state.businessAll = nextProps.businessAll;
    }
    return state;
  }

  componentDidMount() {
    document.addEventListener("keypress", (e) => this.blurActiveElement(e));
    this.getBusinessAll();
  }

  componentWillUnmount() {
    document.removeEventListener("keypress", (e) => this.blurActiveElement(e));
  }

  blurActiveElement = (e) => {
    if (e.keyCode === 13) {
      document.activeElement.blur();
    }
  };

  getBusinessAll = () => {
    this.props.fetchBusiness().then(() => {
      this.setState({ businessAll: this.props.businessAll });
    });
  };

  handleSubmit = (data) => {
    const { business } = this.props;
    if (!business.hasOwnProperty("id")) {
      return this.props
        .addBusiness(data)
        .then((res) => {
          this.actionsCallback();
          store.addNotification({
            ...alertOptions,
            message: "Operazione effettuata",
            type: "success",
          });
        })
        .catch(() => {
          this.actionsCallback();
          store.addNotification({
            ...alertOptions,
            message: "Impossibile completare l'operazione",
            type: "danger",
          });
        });
    }
    data.id = business.id;
    return this.props
      .updateBusiness(data)
      .then(() => {
        this.actionsCallback();
        store.addNotification({
          ...alertOptions,
          message: "Operazione effettuata",
          type: "success",
        });
      })
      .catch(() => {
        this.actionsCallback();
        store.addNotification({
          ...alertOptions,
          message: "Impossibile completare l'operazione",
          type: "danger",
        });
      });
  };

  actionsCallback = () => {
    this.closeForm();
  };

  openForm = () => {
    window.scrollTo(0, 0);
    this.setState({ formOpened: true });
    setTimeout(() => {
      document.getElementsByName("descrizione")[0].focus();
    }, 500);
  };

  closeForm = () => {
    this.setState({ formOpened: false });
    this.businessForm.current.reset();
    this.props.resetForm();
  };

  onDelete = (data) => {
    confirmAlert({
      title: "Sei sicuro?",
      message: "Vuoi davvero elimiare l'elemento?",
      buttons: [
        {
          label: "Si",
          onClick: () => {
            this.props
              .deleteBusiness(data)
              .then(() => {
                this.actionsCallback();
                store.addNotification({
                  ...alertOptions,
                  message: "Operazione effettuata",
                  type: "success",
                });
              })
              .catch(() => {
                store.addNotification({
                  ...alertOptions,
                  message: "Impossibile completare l'operazione",
                  type: "danger",
                });
              });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  getBusiness = (data) => {
    this.openForm();
    this.onGetBusiness(data);
  };

  onGetBusiness = (data) => {
    this.props.getBusiness(data);
  };

  goToMenu = (id) => {
    this.props.history.push(`attivita/${id}`);
  };

  onDrop(picture) {
    const { businessSlected } = this.state;
    if (businessSlected) {
      const file = picture[0];
      const splitName = file.name.split(".");
      splitName.pop();
      if (checkFile(file)) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const image = {
            base64: reader.result.split(",")[1],
            type: file.type,
            business: businessSlected,
          };
          //SEND IMAGE
          this.props
            .setBusinessImage(image)
            .then(() => {
              this.closeOverlay();
              store.addNotification({
                ...alertOptions,
                message: "Operazione effettuata",
                type: "success",
              });
            })
            .catch(() => {
              store.addNotification({
                ...alertOptions,
                message: "Impossibile completare l'operazione",
                type: "danger",
              });
            });
        };
        reader.onerror = (error) => {
          store.addNotification({
            ...alertOptions,
            message: "Impossibile completare l'operazione",
            type: "danger",
          });
        };
      }
    }
  }

  removeImage = (id) => {
    confirmAlert({
      title: "Sei sicuro?",
      message: "Vuoi davvero elimiare l'immagine?",
      buttons: [
        {
          label: "Si",
          onClick: () => {
            this.props
              .deleteBusinessImage(id)
              .then(() => {
                store.addNotification({
                  ...alertOptions,
                  message: "Operazione effettuata",
                  type: "success",
                });
              })
              .catch(() => {
                store.addNotification({
                  ...alertOptions,
                  message: "Impossibile completare l'operazione",
                  type: "danger",
                });
              });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  addImage = (id) => {
    this.openOverlay(id);
  };

  openOverlay = (id) => {
    this.setState({
      overlayOpened: true,
      businessSlected: id,
    });
  };

  closeOverlay = () => {
    this.setState({
      overlayOpened: false,
      businessSlected: null,
    });
  };

  render() {
    const {
      isLoadingBusinessAll,
      isLoadingBusiness,
      isUpdatingBusiness,
      business,
    } = this.props;
    const { businessAll, formOpened, overlayOpened } = this.state;

    return (
      <>
        <PageHelmet pageTitle="Attività" />
        <div className="ft-page-title">
          <div>Attività</div>
        </div>
        <AnimateHeight duration={350} height={formOpened ? "auto" : 0}>
          <div className="row mb-4">
            <div className="col col-12">
              <div className="card ft-box">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h6>
                      {business.hasOwnProperty("id") ? "Modifica" : "Aggiungi"}{" "}
                      attività
                    </h6>
                    <button
                      className="ft-btn-close-form btn btn-sm ft-btn-warning"
                      onClick={this.closeForm}
                    >
                      Chiudi
                    </button>
                  </div>
                  {(isLoadingBusiness || isUpdatingBusiness) && <LoadingData />}
                  <BusinessForm
                    onSubmit={this.handleSubmit}
                    ref={this.businessForm}
                    initialValues={business}
                  />
                </div>
              </div>
            </div>
          </div>
        </AnimateHeight>
        <div className={`overlay-image ${overlayOpened ? "isOpened" : ""}`}>
          <GrClose className="close-overlay" onClick={this.closeOverlay} />
          <ImageUploader
            withIcon={true}
            onChange={this.onDrop}
            imgExtension={[".jpg", ".gif", ".png", ".gif"]}
            maxFileSize={1048576}
            buttonText="Scegli immagine"
            label="Dimensione massima: 1Mb, accetta: jpg, jpeg, png"
            buttonStyles={{ backgroundColor: "#f0555d" }}
            singleImage
          />
        </div>
        <div className="row">
          <div className="col col-12">
            <div className="card ft-box">
              <div className="card-body ft-data-table-body">
                {isLoadingBusinessAll ? (
                  <LoadingData />
                ) : (
                  <>
                    <div className="d-flex justify-content-end mt-2 mb-4">
                      <button
                        className="ft-btn-add-element btn ft-btn-primary btn-circle"
                        onClick={this.openForm}
                      >
                        <FiPlus />
                      </button>
                    </div>
                    {businessAll.response && businessAll.response.length > 0 ? (
                      <ul className="business">
                        {businessAll.response.map(
                          ({ id, descrizione, extension }, index) => {
                            const path = extension
                              ? `${server}downloadBusinessImage?param=${id}.${extension}#${new Date().getTime()}`
                              : "/assets/images/empty.jpg";
                            return (
                              <li className="draggable-item" key={index}>
                                <div className="d-flex align-items-center">
                                  <div className="d-flex align-items-center">
                                    <div
                                      className="mr-3 image-business"
                                      onClick={
                                        !extension
                                          ? () => this.addImage(id)
                                          : null
                                      }
                                      style={{
                                        backgroundImage: `url(${path})`,
                                      }}
                                    >
                                      {extension && (
                                        <div
                                          className="overlay"
                                          onClick={() => this.removeImage(id)}
                                        >
                                          <FaTrash />
                                        </div>
                                      )}
                                    </div>
                                    <div>{descrizione}</div>
                                  </div>
                                  <div className="ml-auto">
                                    <div className="d-flex align-items-center">
                                      <button
                                        id={id}
                                        className="btn ft-btn-secondary btn-sm"
                                        onClick={() => this.goToMenu(id)}
                                      >
                                        Menu
                                      </button>
                                      <button
                                        id={id}
                                        className="btn btn-circle btn-table warning btn-sm ml-2"
                                        onClick={() => this.getBusiness(id)}
                                      >
                                        <FiEdit2 />
                                      </button>
                                      <button
                                        id={id}
                                        className="btn btn-circle btn-table danger btn-sm ml-1"
                                        onClick={() => this.onDelete(id)}
                                      >
                                        <FiTrash />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    ) : (
                      <div>Nessuna attività inserita</div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoadingBusinessAll: state.business.isLoadingBusinessAll,
    businessAll: state.business.businessAll,
    business: state.business.business,
    isLoadingBusiness: state.business.isLoadingBusiness,
    isUpdatingBusiness: state.business.isLoadingBusiness,
  };
};

export default connect(mapStateToProps, {
  fetchBusiness,
  getBusiness,
  addBusiness,
  deleteBusiness,
  updateBusiness,
  setBusinessImage,
  deleteBusinessImage,
  resetForm,
})(Attivita);
