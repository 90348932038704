const initialState = {
  isUpdatingOrdine: false,
  ordini: {},
  isLoadingOrdini: false,
  isLoadingOrdine: false,
  ordine: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_ORDINE_PENDING": {
      return {
        ...state,
        isUpdatingOrdine: true,
      };
    }
    case "ADD_ORDINE_FULFILLED": {
      return {
        ...state,
        isUpdatingOrdine: false,
      };
    }
    case "FETCH_ORDINI_PENDING": {
      return {
        ...state,
        isLoadingOrdini: true,
      };
    }
    case "FETCH_ORDINI_FULFILLED": {
      return {
        ...state,
        isLoadingOrdini: false,
        ordini: action.payload.data,
      };
    }
    case "FETCH_ORDINI_REJECTED": {
      return {
        ...state,
        isLoadingOrdini: true,
      };
    }
    case "GET_ORDINE_PENDING": {
      return {
        ...state,
        isLoadingOrdine: true,
      };
    }
    case "GET_ORDINE_FULFILLED": {
      return {
        ...state,
        isLoadingOrdine: false,
        ordine: action.payload.data.response,
      };
    }
    case "GET_ORDINE_REJECTED": {
      return {
        ...state,
        isLoadingOrdine: false,
      };
    }
    case "UPDATE_ORDINE_PENDING": {
      return {
        ...state,
        isLoadingOrdine: true,
      };
    }
    case "UPDATE_ORDINE_FULFILLED": {
      return {
        ...state,
        isUpdatingOrdine: false,
      };
    }
    case "DELETE_ORDINE_PENDING": {
      return {
        ...state,
        isUpdatingOrdine: true,
      };
    }
    case "DELETE_ORDINE_FULFILLED": {
      return {
        ...state,
        isUpdatingOrdine: false,
      };
    }
    case "DELETE_ORDINE_REJECTED": {
      return {
        ...state,
        isUpdatingOrdine: false,
        //GESTIRE
      };
    }
    case "RESET_FORM_ORDINE": {
      return {
        ...state,
        ordine: null,
        isLoadingOrdine: false,
        isLoadingOrdini: false,
      };
    }
    default:
      return state;
  }
};

export default reducer;
