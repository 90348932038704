import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import { FiMoreHorizontal } from "react-icons/fi";
import LogoContainer from './LogoContainer';

import { menuItems } from "./menuItems";

import { toggleCollapseSidebar, toggleSidebarMobile, toggleHeaderMobile } from "../../../redux/actions/sidebar_actions"


class Sidebar extends Component {

    componentDidMount() {
        window.addEventListener('resize', () => {
            const width = window.innerWidth;
            const { sidebarCollapsed, sidebarMobileOn, headerMobileOn } = this.props;
            if (width <= 1024) {
                if (sidebarCollapsed) {
                    this.props.toggleCollapseSidebar();
                }
            } else {
                if (sidebarMobileOn) {
                    this.props.toggleSidebarMobile();
                }
                if (headerMobileOn) {
                    this.props.toggleHeaderMobile();
                }
            }
        })
    }


    onToggleSidebar = () => {
        this.props.toggleCollapseSidebar();
    }

    onToggleSidebarmobile = () => {
        const { sidebarMobileOn } = this.props;
        if (sidebarMobileOn) {
            this.props.toggleSidebarMobile();
        }
    }

    render() {
        return (
            <div className="ft-sidebar">
                <LogoContainer onToggleSidebar={this.onToggleSidebar} />

                <div className="ft-main-menu-container">
                    <ul className="ft-main-menu">
                        {
                            menuItems.map((menuItem) => (
                                <div key={menuItem.label} >
                                    <li className="ft-menu-category" data-tip={menuItem.label}><FiMoreHorizontal /><span>{menuItem.label}</span></li><ReactTooltip place="right" effect="solid" />
                                    {
                                        menuItem.items.map((item) => (
                                            <li key={item.path} >
                                                <NavLink to={`/${item.path}`} activeClassName="ft-menu-active" data-tip={item.label} onClick={this.onToggleSidebarmobile}>{item.icon}<span>{item.label}</span></NavLink><ReactTooltip place="right" effect="solid" />
                                            </li>
                                        ))
                                    }
                                </div>
                            ))
                        }
                    </ul>
                </div>
            </div>
        )
    }
}

export default connect(null, { toggleCollapseSidebar, toggleSidebarMobile, toggleHeaderMobile })(Sidebar);
