import React, { Component } from 'react';
import { FiChevronsLeft } from "react-icons/fi";
import { withRouter } from "react-router-dom";

class LogoContainer extends Component {

    goToHome = () => {
        this.props.history.push("/")
    }

    render() {

        const { onToggleSidebar } = this.props;

        return (
            <div className="ft-logo-container">
                <div className="ft-logo" onClick={this.goToHome}>
                    <img alt="logo" src="/assets/images/qrmenu.svg" />
                </div>
                <div className="ft-toggle-sidebar" onClick={onToggleSidebar}>
                    <FiChevronsLeft />
                </div>
            </div>
        )
    }
}

export default withRouter(LogoContainer);