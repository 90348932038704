import React, { Component } from "react";
import PageHelmet from "../Components/Utils/PageHelmet";
import LoadingData from "../Components/Utils/Datatable/LoadingData";
import NoDataComponent from "../Components/Utils/Datatable/NoDataComponent";
import { connect } from "react-redux";
import isEmpty from "plain-object-is-empty";
import {
  fetchMateriePrime,
  resetForm,
  getMateriaPrima,
  updateMateriaPrima,
  addMateriaPrima,
  deleteMateriaPrima,
} from "../redux/actions/materiaPrimaActions";
import DataTable from "react-data-table-component";
import memoize from "memoize-one";
import { store } from "react-notifications-component";
import alertOptions from "../Components/Alert/alertOptions";
import { FiChevronDown, FiPlus, FiTrash, FiEdit2 } from "react-icons/fi";
import AnimateHeight from "react-animate-height";
import MateriePrimeForm from "../Components/MateriePrime/MateriePrimeForm";
import { confirmAlert } from "react-confirm-alert";

const columns = memoize((actionHandler) => [
  {
    name: "Descrizione",
    selector: "descrizione",
    sortable: true,
  },
  {
    name: "Costo €",
    selector: "costo",
    sortable: true,
    width: "100px",
    right: true,
    cell: (row) => (
      <div className="primary" style={{ fontWeight: "700" }}>
        {row.costo.toFixed(3)}
      </div>
    ),
  },
  {
    name: "Prezzo €",
    selector: "prezzoVendita",
    sortable: true,
    width: "100px",
    right: true,
    cell: (row) => (
      <div className="secondary" style={{ fontWeight: "700" }}>
        {row.prezzoVendita.toFixed(3)}
      </div>
    ),
  },
  {
    sortable: false,
    width: "100px",
    right: true,
    cell: (row) => (
      <div className="d-flex">
        <button
          className="btn btn-circle btn-sm btn-table warning"
          onClick={() => actionHandler("update", row.id)}
        >
          <FiEdit2 />
        </button>
        <button
          className="btn btn-circle btn-sm ml-1 btn-table danger"
          onClick={() => actionHandler("delete", row.id)}
        >
          <FiTrash />
        </button>
      </div>
    ),
  },
]);

class MateriePrime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: "descrizione",
      sortDirection: "ASC",
      descrizione: "",
      pageNumber: 1,
      pageSize: 25,
      formOpened: false,
    };

    this.materiePrimeForm = React.createRef();
    this.blurActiveElement = this.blurActiveElement.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keypress", (e) => this.blurActiveElement(e));
    this.getMateriePrime();
  }

  componentWillUnmount() {
    document.removeEventListener("keypress", (e) => this.blurActiveElement(e));
  }

  blurActiveElement = (e) => {
    if (e.keyCode === 13) {
      document.activeElement.blur();
    }
  };

  getMateriePrime = () => {
    const { sortBy, sortDirection, descrizione, pageNumber, pageSize } =
      this.state;
    this.props.fetchMateriePrime(
      sortBy,
      sortDirection,
      descrizione,
      pageNumber - 1,
      pageSize
    );
  };

  handleSubmit = (data) => {
    const { materiaPrima } = this.props;
    if (!materiaPrima.hasOwnProperty("id")) {
      return this.props
        .addMateriaPrima(data)
        .then(() => {
          this.actionsCallback();
          store.addNotification({
            ...alertOptions,
            message: "Operazione effettuata",
            type: "success",
          });
        })
        .catch(() => {
          this.actionsCallback();
          store.addNotification({
            ...alertOptions,
            message: "Impossibile completare l'operazione",
            type: "danger",
          });
        });
    }
    data.id = materiaPrima.id;
    return this.props
      .updateMateriaPrima(data)
      .then(() => {
        this.actionsCallback();
        store.addNotification({
          ...alertOptions,
          message: "Operazione effettuata",
          type: "success",
        });
      })
      .catch(() => {
        this.actionsCallback();
        store.addNotification({
          ...alertOptions,
          message: "Impossibile completare l'operazione",
          type: "danger",
        });
      });
  };

  actionsCallback = () => {
    this.getMateriePrime();
    this.closeForm();
  };

  handlePageChange = (page, totalRows) => {
    this.setState(
      {
        pageNumber: page,
      },
      () => this.getMateriePrime()
    );
  };

  handlePerRowsChange = (currentRowsPerPage, currentPage) => {
    this.setState(
      {
        pageSize: currentRowsPerPage,
      },
      () => this.getMateriePrime()
    );
  };

  onSort = (column, sortDirection) => {
    const { selector } = column;
    this.setState(
      {
        sortBy: selector,
        sortDirection: sortDirection.toUpperCase(),
      },
      () => this.getMateriePrime()
    );
  };

  onFilter = ({ target }) => {
    this.setState(
      {
        pageNumber: 1,
        descrizione: target.value,
      },
      () => this.getMateriePrime()
    );
  };

  onGetMateriaPrima = (data) => {
    this.props.getMateriaPrima(data);
  };

  subActions = () => (
    <div className="ft-table-search-container  mt-3">
      <input
        type="search"
        className="ft-table-search ft-form-control form-control datatable-search"
        name="search"
        value={this.state.descrizione}
        role="search"
        placeholder="Cerca..."
        onChange={this.onFilter}
      />
      <button
        className="ft-btn-add-element btn ft-btn-primary btn-circle"
        onClick={this.openForm}
        disabled={this.state.formOpened}
      >
        <FiPlus />
      </button>
    </div>
  );

  openForm = () => {
    window.scrollTo(0, 0);
    this.setState({ formOpened: true });
    setTimeout(() => {
      document.getElementsByName("descrizione")[0].focus();
    }, 500);
  };

  closeForm = () => {
    this.setState({ formOpened: false });
    this.materiePrimeForm.current.reset();
    this.props.resetForm();
  };

  onDelete = (data) => {
    confirmAlert({
      title: "Sei sicuro?",
      message: "Vuoi davvero elimiare l'elemento?",
      buttons: [
        {
          label: "Si",
          onClick: () => {
            this.props
              .deleteMateriaPrima(data)
              .then(() => {
                this.actionsCallback();
                store.addNotification({
                  ...alertOptions,
                  message: "Operazione effettuata",
                  type: "success",
                });
              })
              .catch(() => {
                store.addNotification({
                  ...alertOptions,
                  message: "Impossibile completare l'operazione",
                  type: "danger",
                });
              });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  actionHandler = (action, data) => {
    switch (action) {
      case "update":
        this.openForm();
        this.onGetMateriaPrima(data);
        break;
      case "delete":
        this.onDelete(data);
        break;
      default:
        break;
    }
  };

  render() {
    const {
      isLoadingMateriePrime,
      materiePrime,
      isLoadingMateriaPrima,
      isUpadtingMateriaPrima,
      materiaPrima,
    } = this.props;
    const { pageSize, sortDirection, pageNumber, formOpened } = this.state;

    return (
      <>
        <PageHelmet pageTitle="Materie prime" />
        <div className="ft-page-title">
          <div>Materie Prime</div>
        </div>
        <AnimateHeight duration={350} height={formOpened ? "auto" : 0}>
          <div className="row mb-4">
            <div className="col col-12">
              <div className="card ft-box">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h6>
                      {materiaPrima.hasOwnProperty("id")
                        ? "Modifica"
                        : "Aggiungi"}{" "}
                      materia prima
                    </h6>
                    <button
                      className="ft-btn-close-form btn btn-sm ft-btn-warning"
                      onClick={this.closeForm}
                    >
                      Chiudi
                    </button>
                  </div>
                  {(isLoadingMateriaPrima || isUpadtingMateriaPrima) && (
                    <LoadingData />
                  )}
                  <MateriePrimeForm
                    onSubmit={this.handleSubmit}
                    ref={this.materiePrimeForm}
                    initialValues={
                      isEmpty(materiaPrima)
                        ? { ...materiaPrima, costo: "0", prezzoVendita: "0" }
                        : materiaPrima
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </AnimateHeight>
        <div className="row">
          <div className="col col-12">
            <div className="card ft-box">
              <div className="card-body ft-data-table-body">
                {isLoadingMateriePrime && <LoadingData />}
                <DataTable
                  className="ft-table"
                  columns={columns(this.actionHandler)}
                  defaultSortField="nome"
                  defaultSortAsc={sortDirection === "asc" ? true : false}
                  data={materiePrime.content}
                  paginationTotalRows={materiePrime.totalElements}
                  noHeader
                  allowOverflow
                  onSort={this.onSort}
                  sortIcon={<FiChevronDown />}
                  sortServer
                  subHeader
                  progressPending={false}
                  pagination
                  paginationServer
                  paginationRowsPerPageOptions={[1, 2, 10, 25, 50, 100]}
                  paginationPerPage={pageSize}
                  paginationDefaultPage={pageNumber}
                  onChangePage={this.handlePageChange}
                  onChangeRowsPerPage={this.handlePerRowsChange}
                  paginationComponentOptions={{
                    rowsPerPageText: "Elementi per pagina",
                    rangeSeparatorText: "di",
                  }}
                  subHeaderComponent={this.subActions()}
                  noDataComponent={<NoDataComponent />}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoadingMateriePrime: state.materiePrime.isLoadingMateriePrime,
    materiePrime: state.materiePrime.materiePrime,
    materiaPrima: state.materiePrime.materiaPrima,
    isLoadingMateriaPrima: state.materiePrime.isLoadingMateriaPrima,
    isUpadtingMateriaPrima: state.materiePrime.isUpadtingMateriaPrima,
  };
};

export default connect(mapStateToProps, {
  fetchMateriePrime,
  resetForm,
  getMateriaPrima,
  updateMateriaPrima,
  addMateriaPrima,
  deleteMateriaPrima,
})(MateriePrime);
