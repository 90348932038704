import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <div className="ft-footer">
                2021 &copy; Softplus S.r.l.
            </div>
        )
    }
}

export default Footer;
