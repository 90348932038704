import React, { Component } from "react";
import PageHelmet from "../Components/Utils/PageHelmet";
import { store } from "react-notifications-component";
import alertOptions from "../Components/Alert/alertOptions";
import { checkFile } from "../Components/Business/checkFile";
import spring from "../axios/axios";

class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: "",
    };
  }

  uploadImage = (e) => {
    e.preventDefault();
    if (e.target[0].files.length === 1) {
      const file = e.target[0].files[0];
      const splitName = file.name.split(".");
      splitName.pop();
      if (checkFile(file)) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const image = {
            name: splitName.join(),
            base64: reader.result.split(",")[1],
            type: file.type,
          };
          //SEND IMAGE
          spring
            .post("upload", image)
            .then((res) => {
              this.setState({
                path:
                  "http://localhost:8080/download?param=" + res.data.response,
              });
            })
            .catch((err) => console.log(err));
        };
        reader.onerror = (error) => {
          store.addNotification({
            ...alertOptions,
            message: "Impossibile completare l'operazione",
            type: "danger",
          });
        };
      }
    }
  };

  render() {
    const { path } = this.state;

    return (
      <>
        <PageHelmet pageTitle="Upload" />
        <div className="ft-page-title">
          <div>Upload</div>
        </div>
        <div className="row">
          <div className="col col-12">
            <div className="card ft-box">
              <div className="card-body ft-data-table-body">
                <form action="" onSubmit={this.uploadImage}>
                  <div className="row">
                    <div className="col col-12">
                      <input type="file" name="image" id="image" />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col col-12">
                      <button className="btn ft-btn-primary">Upload</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col col-12">
            <img src={path} alt="" />
          </div>
        </div>
      </>
    );
  }
}

export default Upload;
