const initialState = {
    sidebarCollapsed: false,
    sidebarMobileOn: false,
    headerMobileOn: false,
    panelPietanzeCollapsed:false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'TOGGLE_SIDEBAR_COLLAPSE': {
            return {
                ...state,
                sidebarCollapsed: !state.sidebarCollapsed,
            }
        }
        case 'TOGGLE_SIDEBAR_MOBILE': {
            return {
                ...state,
                sidebarMobileOn: !state.sidebarMobileOn,
            }
        }
        case 'TOGGLE_HEADER_MOBILE': {
            return {
                ...state,
                headerMobileOn: !state.headerMobileOn,
            }
        }
        case 'TOGGLE_PANEL_PIETANZE': {
            return {
                ...state,
                panelPietanzeCollapsed: !state.panelPietanzeCollapsed,
            }
        }
        default:
            return state;
    }
}

export default reducer;