const initialState = {
    pietanze:{},
    isLoadingPietanze:false,
    isLoadingPietanza:false,
    isUpdatingPietanza:false,
    pietanza:{},
    pietanzeTemp:{},
    isLoadingPietanzeTemp: false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_PIETANZE_PAGING_PENDING': {
            return {
                ...state,
                isLoadingPietanze: true,
            }
        }
        case 'FETCH_PIETANZE_PAGING_FULFILLED': {
            return {
                ...state,
                isLoadingPietanze: false,
                pietanze: action.payload.data,
            }
        }
        case 'FETCH_PIETANZE_BY_TIPOLOGIA_PENDING': {
            return {
                ...state,
                isLoadingPietanze: true,
            }
        }
        case 'FETCH_PIETANZE_BY_TIPOLOGIA_FULFILLED': {
            return {
                ...state,
                isLoadingPietanze: false,
                pietanze: action.payload.data,
            }
        }
        case 'FETCH_PIETANZE_BY_BUSINESS_AND_BY_TIPOLOGIA_PENDING': {
            return {
                ...state,
                isLoadingPietanzeTemp: true,
            }
        }
        case 'FETCH_PIETANZE_BY_BUSINESS_AND_BY_TIPOLOGIA_FULFILLED': {
            return {
                ...state,
                isLoadingPietanzeTemp: false,
                pietanzeTemp: action.payload.data,
            }
        }
        case 'GET_PIETANZA_PENDING': {
            return {
                ...state,
                isLoadingPietanza: true,
            }
        }
        case 'GET_PIETANZA_FULFILLED': {
            return {
                ...state,
                isLoadingPietanza: false,
                pietanza: action.payload.data.response,
            }
        }
        case 'ADD_PIETANZA_PENDING': {
            return {
                ...state,
                isUpdatingPietanza: true
            }
        }
        case 'ADD_PIETANZA_FULFILLED': {
            return {
                ...state,
                isUpdatingPietanza: false
            }
        }
        case 'UPDATE_PIETANZA_PENDING': {
            return {
                ...state,
                isUpdatingPietanza: true
            }
        }
        case 'UPDATE_PIETANZA_FULFILLED': {
            return {
                ...state,
                isUpdatingPietanza: false
            }
        }
        case 'DELETE_PIETANZA_PENDING': {
            return {
                ...state,
                isUpdatingPietanza:true
            }
        }
        case 'DELETE_PIETANZA_FULFILLED': {
            return {
                ...state,
                isUpdatingPietanza:false
            }
        }
        case 'DELETE_PIETANZA_REJECTED': {
            return {
                ...state,
                isUpdatingPietanza:false
                //GESTIRE
            }
        }
        case 'RESET_FORM_PIETANZA': {
            return {
                ...state,
                pietanza: {},
                isLoadingPietanza:false,
                isUpdatingPietanza:false,
            }
        }
        case 'RESET_PIETANZE_TEMP': {
            return {
                ...state,
                pietanza:{},
                isLoadingPietanza:false,
                pietanzeTemp:{},
                isLoadingPietanzeTemp:false
            }
        }
        default:
            return state;
    }
}

export default reducer;
