const initialState = {
    businessAll:{},
    isLoadingBusinessAll:false,
    business: {},
    isLoadingBusiness:false,
    isUpdatingBusiness: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_BUSINESS_PENDING': {
            return {
                ...state,
                isLoadingBusinessAll: true,
            }
        }
        case 'FETCH_BUSINESS_FULFILLED': {
            return {
                ...state,
                isLoadingBusinessAll: false,
                businessAll: action.payload.data,
            }
        }
        case 'GET_BUSINESS_BY_ID_PENDING': {
            return {
                ...state,
                isLoadingBusiness: true,
            }
        }
        case 'GET_BUSINESS_BY_ID_FULFILLED': {
            return {
                ...state,
                isLoadingBusiness: false,
                business: action.payload.data.response,
            }
        }
        case 'ADD_BUSINESS_PENDING': {
            return {
                ...state,
                isUpdatingBusiness: true
            }
        }
        case 'ADD_BUSINESS_FULFILLED': {
            return {
                ...state,
                isUpdatingBusiness: false,
                businessAll: {...state.businessAll, response:[...state.businessAll.response, action.payload.data.response]}
            }
        }
        case 'ADD_BUSINESS_REJECTED': {
            return {
                ...state,
                isUpdatingBusiness: false,
                //GESTIRE,
            }
        }
        case 'UPDATE_BUSINESS_PENDING': {
            return {
                ...state,
                isUpdatingBusiness: true
            }
        }
        case 'UPDATE_BUSINESS_FULFILLED': {
            return {
                ...state,
                isUpdatingBusiness: false,
                businessAll: {...state.businessAll, response:state.businessAll.response
                    .map(business => business.id !== action.payload.data.response.id ? business : action.payload.data.response)}
            }
        }
        case 'UPDATE_BUSINESS_REJECTED': {
            return {
                ...state,
                isUpdatingBusiness: false,
                //GESTIRE,
            }
        }
        case 'DELETE_BUSINESS_PENDING': {
            return {
                ...state,
                isUpdatingBusiness:true
            }
        }
        case 'DELETE_BUSINESS_FULFILLED': {
            return {
                ...state,
                isUpdatingBusiness:false,
                businessAll: {...state.businessAll, response:state.businessAll.response.filter(business => business.id !== action.payload.data.response.id)}
            }
        }
        case 'DELETE_BUSINESS_REJECTED': {
            return {
                ...state,
                isUpdatingBusiness:false
                //GESTIRE
            }
        }
        case 'RESET_FORM_BUSINESS': {
            return {
                ...state,
                business: {},
                isLoadingBusiness:false,
                isUpdatingBusiness:false,
            }
        }
        default:
            return state;
    }
}

export default reducer;