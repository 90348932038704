const initialState = {
    materiePrime:{},
    isLoadingMateriePrime:false,
    materiaPrima:{},
    isLoadingMateriaPrima:false,
    isUpdatingMateriaPrima:false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_MATERIE_PRIME_PENDING': {
            return {
                ...state,
                isLoadingMateriePrime: true,
            }
        }
        case 'FETCH_MATERIE_PRIME_FULFILLED': {
            return {
                ...state,
                isLoadingMateriePrime: false,
                materiePrime: action.payload.data,
            }
        }
        case 'GET_MATERIA_PRIMA_PENDING': {
            return {
                ...state,
                isLoadingMateriaPrima: true,
            }
        }
        case 'GET_MATERIA_PRIMA_FULFILLED': {
            return {
                ...state,
                isLoadingMateriaPrima: false,
                materiaPrima: action.payload.data.response,
            }
        }
        case 'ADD_MATERIA_PRIMA_PENDING': {
            return {
                ...state,
                isUpdatingMateriaPrima: true
            }
        }
        case 'ADD_MATERIA_PRIMA_FULFILLED': {
            return {
                ...state,
                isUpdatingMateriaPrima: false,
            }
        }
        case 'ADD_MATERIA_PRIMA_REJECTED': {
            return {
                ...state,
                isUpdatingMateriaPrima: false,
                //GESTIRE,
            }
        }
        case 'UPDATE_MATERIA_PRIMA_PENDING': {
            return {
                ...state,
                isUpdatingMateriaPrima: true
            }
        }
        case 'UPDATE_MATERIA_PRIMA_FULFILLED': {
            return {
                ...state,
                isUpdatingMateriaPrima: false,
            }
        }
        case 'UPDATE_MATERIA_PRIMA_REJECTED': {
            return {
                ...state,
                isUpdatingMateriaPrima: false,
                //GESTIRE,
            }
        }
        case 'DELETE_MATERIA_PRIMA_PENDING': {
            return {
                ...state,
                isUpdatingMateriaPrima:true
            }
        }
        case 'DELETE_MATERIA_PRIMA_FULFILLED': {
            return {
                ...state,
                isUpdatingMateriaPrima:false
            }
        }
        case 'DELETE_MATERIA_PRIMA_REJECTED': {
            return {
                ...state,
                isUpdatingMateriaPrima:false
                //GESTIRE
            }
        }
        case 'RESET_FORM_MATERIA_PRIMA': {
            return {
                ...state,
                materiaPrima: {},
                isLoadingMateriaPrima:false,
                isUpdatingMateriaPrima:false,
            }
        }
        default:
            return state;
    }
}

export default reducer;