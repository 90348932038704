import React, { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import Sidebar from './Components/Layout/Sidebar/Sidebar';
import MainOverlay from './Components/Layout/Sidebar/MainOverlay';
import Header from "./Components/Layout/Header/Header";
import Footer from "./Components/Layout/Footer/Footer";
import ScrollIntoView from './Components/Layout/Utils/ScrollIntoView';
import MateriePrime from './Views/MateriePrime';
import Tipologie from './Views/Tipologie';
import Pietanze from './Views/Pietanze';
import Attivita from './Views/Attivita';
import Menu from './Views/Menu';
import Upload from './Views/Upload';
import Comande from './Views/Comande';

class App extends Component {
  render() {
    const { sidebarCollapsed, sidebarMobileOn, headerMobileOn, panelPietanzeCollapsed } = this.props

    return (
      <div className={`ft-main-container ${sidebarCollapsed ? 'ft-sidebar-collapsed' : ''} ${panelPietanzeCollapsed ? 'panel-pietanze-collapsed' : ''} ${sidebarMobileOn ? 'ft-sidebar-mobile-on' : ''} ${headerMobileOn ? 'ft-header-mobile-on' : ''} `}>
        <MainOverlay />
        <Sidebar sidebarCollapsed={sidebarCollapsed} sidebarMobileOn={sidebarMobileOn} headerMobileOn={headerMobileOn} />
        <Header />
        <Footer />
        <div className="ft-page-container">
          <ScrollIntoView>
            <Switch>
              <Route exact path="/materie-prime" component={MateriePrime} />
              <Route exact path="/tipologie" component={Tipologie} />
              <Route exact path="/pietanze" component={Pietanze} />
              <Route exact path="/attivita" component={Attivita} />
              <Route exact path="/attivita/:id" component={Menu} />
              <Route exact path="/upload" component={Upload} />
              <Route exact path="/comande" component={Comande} />
              <Redirect exact from="/" to='/attivita' />
            </Switch>
          </ScrollIntoView>
        </div>
      </div>
    );
  }

}

const mapStateToProps = state => {

  return {
    sidebarCollapsed: state.sidebar.sidebarCollapsed,
    sidebarMobileOn: state.sidebar.sidebarMobileOn,
    headerMobileOn: state.sidebar.headerMobileOn,
    panelPietanzeCollapsed: state.sidebar.panelPietanzeCollapsed
  }
}

export default connect(mapStateToProps)(App)
