import React from "react";
import { BsDot } from "react-icons/bs";

const PietanzeTableDetails = ({ data }) => {
  return (
    <div
      style={{
        lineHeight: "2rem",
        overflow: "hidden",
        background: "rgb(242 243 248)",
      }}
    >
      <div className="row p-3">
        <div className="co col-12">
          <div>
            <span className="font-weight-bold">Matere prime: </span>
            <br></br>
            {!data.materieprime || data.materieprime.length === 0 ? (
              <div>Nessuna materia prima inserita</div>
            ) : (
              data.materieprime
                .sort((a, b) => a.descrizione.localeCompare(b.descrizione))
                .map((materiaprima, index) => (
                  <span key={index}>
                    {materiaprima.descrizione}
                    {index < data.materieprime.length - 1 ? (
                      <BsDot className="primary" />
                    ) : (
                      ""
                    )}
                  </span>
                ))
            )}
          </div>
        </div>
        <div className="co col-12">
          <div>
            <span className="font-weight-bold">Info: </span>
            <br></br>
            {!data.info ? (
              <div>Nessuna informazione inserita</div>
            ) : (
              <div>{data.info}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PietanzeTableDetails;
