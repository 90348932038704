import React from "react";
import moment from "moment";

const getTotale = (data) => {
  var totale = 0;
  data.forEach((element) => {
    totale += element.prezzo * element.numero + element.aggiunta;
  });
  return totale;
};

const ComandeTableDetails = ({ data }) => {
  return (
    <div
      style={{
        lineHeight: "2rem",
        overflow: "hidden",
        background: "rgb(242 243 248)",
      }}
    >
      <div className="row pt-3 pl-3 pr-3 pb-0">
        <div className="col col-12">
          <div>
            <span className="font-weight-bold">Cliente: </span>
            {data.cliente}
            <span className="font-weight-bold ml-3">Orario: </span>{" "}
            {moment(data.date).format("HH:mm")}
          </div>
        </div>
      </div>
      <div className="row pt-3 pl-3 pr-3 pb-0">
        <div className="col col-9">
          <b>Descrizione</b>
        </div>
        <div className="col col-3 text-right">
          <b>Prezzo &euro;</b>
        </div>
      </div>
      <div className="pr-3 pl-3 pb-3 pt-0">
        {data.ordinePietanza.map((pietanza, index) => (
          <div
            className="row mt-2 align-items-center"
            key={index}
            style={{
              borderBottom: "1px solid #eaeaea",
              padding: "15px 0",
            }}
          >
            <div className="col col-9">
              <p style={{ lineHeight: "18px", fontWeight: "500" }}>
                {pietanza.numero} x {pietanza.descrizione}
                <br></br>
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    whiteSpace: "pre-line",
                  }}
                >
                  {pietanza.note}
                </span>
              </p>
            </div>
            <div className="col col-3 text-right" style={{ fontWeight: "500" }}>
              {(pietanza.prezzo * pietanza.numero + pietanza.aggiunta).toFixed(
                2
              )}
            </div>
          </div>
        ))}
        <div
          className="row mt-2 align-items-center"
          style={{ fontWeight: "bolder" }}
        >
          <div className="col col-9">TOTALE</div>
          <div className="col col-3 text-right">
            {getTotale(data.ordinePietanza).toFixed(2)}
          </div>
        </div>
        <div
          className="row mt-2 align-items-center"
          style={{ fontWeight: "bolder" }}
        >
          <div className="col col-9">SCONTO</div>
          <div className="col col-3 text-right">{data.sconto.toFixed(2)}</div>
        </div>
        <div
          className="row mt-2 align-items-center"
          style={{ fontWeight: "bolder" }}
        >
          <div className="col col-9">TOTALE SCONTATO</div>
          <div className="col col-3 text-right">
            {(getTotale(data.ordinePietanza) - data.sconto).toFixed(2)}
          </div>
        </div>
      </div>
      {/* <div className="row pt-0 pl-3 pr-3 pb-3">
        <div className="col col-12">
          <div>
            <span className="font-weight-bold">Note: </span>
          </div>
          <div>
            {data.note === "" ? (
              <div>Nessuna nota</div>
            ) : (
              <div>{data.note}</div>
            )}
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default ComandeTableDetails;
