import React from 'react'

const NoDataComponent = () => {
    return (
        <div className="d-flex align-items-center w-100 mt-4">
        <div>Nessun elemento trovato</div>
    </div>
    )
}

export default NoDataComponent;