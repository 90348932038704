const selectOptions = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? "white" : "#282a3c",
        padding: 15,
        backgroundColor: state.isSelected ? "#f0555d" : "white",
        fontWeight: state.isSelected ? '500' : '300',

        ':active': {
            backgroundColor: state.isSelected ? "white" : "#f0555d",
            color: state.isSelected ? "#282a3c" : "white",
            fontWeight: state.isSelected ? '500' : '300',
        },
        ':hover': {
            backgroundColor: state.isSelected ? "#f0555d" : "#f2f3f8",
            color: state.isSelected ? "white" : "#282a3c",
            fontWeight: state.isSelected ? '500' : '300',
        }
    }),
    control: (base, state) => {
        return {
            ...base,
            borderColor: !state.isFocused ? "#e2e5ec" : "#00bfb5",
            borderRadius: '4px!important',
            boxShadow: '0 !important',
            '&:hover': {
                // border: '0 !important'
            },
            '&:active': {
                // border: '0 !important'
            },
            backgroundColor: !state.isDisabled ? "#fff" : '#e9ecef',
            // cursor: !state.isDisabled ? 'not-allowed' : 'not-allowed',
            padding: "0px 8px",
            minWidth: '150px',
            minHeight: '40px',
            color: '#282a3c',
            fontWeight: '400!important',
            '::placholder': {
                fontWeight: '400!important',
                color:"#595d6e!important",
            },
            '&.has-error__control': {
                borderColor: "#f0555d",
            }
        }
    },
    placeholder: (defaultStyles) => ({
        ...defaultStyles,
        fontWeight: '400!important',
        color:"#595d6e!important",
    }),
    valueContainer: (base, state) => ({
        ...base,
        padding: "0",
        marginTop: "0",
        borderColor:"transparent!important",
    }),
    indicatorSeparator: (base) => ({
        ...base,
        display: 'none',
    }),
    dropdownIndicator: defaultStyles => ({
        ...defaultStyles,
        display: 'block',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#282a3c'
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: '2',
        boxShadow: "1px 1px 5px 1px rgba(0, 0, 0, 0.1)",
        webKitBoxShadow: "1px 1px 5px 1px rgba(0, 0, 0, 0.1)",
        '::select': {
            fontWeight: '400!important',
            color: 'red!important'
        }
    }),
    menuList: (provided) => ({
        ...provided,
        padding: 0,
        borderRadius: '4px!important',
        '::-webkit-scrollbar-track': {
            borderRadius: '10px',
            backgroundColor: '#EDF2F4'
        },
        '::-webkit-scrollbar': {
            width: '10px',
            backgroundColor: 'rgb(248, 248, 248)'
        },
        '::-webkit-scrollbar-thumb': {
            borderRadius: '10px',
            backgroundColor: '#cfdce2'
        }
    })
}

export default selectOptions