import spring from "../../axios/axios";

const APIADD = "addOrdine";
const APIGETORDINI = "getOrdiniByBusinessIdAndDate";
const APIGETORDINE = "ordine";
const UPDATEORDINE = "updateOrdine";
const DELETEORDINE = "deleteOrdine";

export const addOrdine = (data) => {
  return async (dispatch) => {
    return dispatch({
      type: "ADD_ORDINE",
      payload: spring.post(APIADD, data),
    });
  };
};

export const getOrdini = (data) => {
  return async (dispatch) => {
    return dispatch({
      type: "FETCH_ORDINI",
      payload: spring.post(APIGETORDINI, {}, { params: data }),
    });
  };
};

export const getOrdine = (data) => {
  return async (dispatch) => {
    return dispatch({
      type: "GET_ORDINE",
      payload: spring.get(`${APIGETORDINE}/${data}`),
    });
  };
};

export const updateOrdine = (data) => {
  return async (dispatch) => {
    return dispatch({
      type: "UPDATE_ORDINE",
      payload: spring.put(UPDATEORDINE, data),
    });
  };
};

export const deleteOrdine = (data) => {
  return async (dispatch) => {
    return dispatch({
      type: "DELETE_ORDINE",
      payload: spring.delete(`${DELETEORDINE}/${data}`),
    });
  };
};

export const resetForm = () => {
  return async (dispatch) => {
    return dispatch({
      type: "RESET_FORM_ORDINE",
    });
  };
};
