import spring from "../../axios/axios"

const APIGET = "tipologie"
const APIGETID = "tipologia"
const APIUPDATE = "updateTipologia"
const APIADD = "addTipologia"
const APIDELETE = "deleteTipologia"

export const fetchTipologie = () => {
    return async dispatch => {
        return dispatch({
            type: 'FETCH_TIPOLOGIE',
            payload: spring.get(APIGET)
        })
    }
}

export const changeOrderTipologie = (start, end) => {
    return async dispatch => {
        return dispatch({
            type: 'UPDATE_TIPOLOGIE',
            payload: spring.get(`${APIGET}/${start}/${end}`)
        })
    }
}

export const getTipologia = data => {
    return async dispatch => {
        return dispatch({
            type: 'GET_TIPOLOGIA',
            payload: spring.get(`${APIGETID}/${data}`)
        })
    }
}


export const addTipologia = data => {
    return async dispatch => {
        return dispatch({
            type: 'ADD_TIPOLOGIA',
            payload: spring.post(APIADD, {}, {params:data})
        })
    }
}

export const updateTipologia = data => {
    return async dispatch => {
        return dispatch({
            type: 'UPDATE_TIPOLOGIA',
            payload: spring.put(APIUPDATE, {},{params:data})
        })
    }
}

export const deleteTipologia = data => {
    return async dispatch => {
        return dispatch({
            type: 'DELETE_TIPOLOGIA',
            payload: spring.delete(`${APIDELETE}/${data}`)
        })
    }
}


export const resetForm = () => {
    return async dispatch => {
        return dispatch({
            type: 'RESET_FORM_TIPOLOGIA'
        })
    }
}