const initialState = {
  isUpdatingComanda: false,
  comande: {},
  isLoadingComande: false,
  isLoadingComanda: false,
  comanda: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_COMANDA_PENDING": {
      return {
        ...state,
        isUpdatingComanda: true,
      };
    }
    case "ADD_COMANDA_FULFILLED": {
      return {
        ...state,
        isUpdatingComanda: false,
      };
    }
    case "FETCH_COMANDE_PENDING": {
      return {
        ...state,
        isLoadingComande: true,
      };
    }
    case "FETCH_COMANDE_FULFILLED": {
      return {
        ...state,
        isLoadingComande: false,
        comande: action.payload.data,
      };
    }
    case "FETCH_COMANDE_REJECTED": {
      return {
        ...state,
        isLoadingComande: true,
      };
    }
    case "GET_COMANDA_PENDING": {
      return {
        ...state,
        isLoadingComanda: true,
      };
    }
    case "GET_COMANDA_FULFILLED": {
      return {
        ...state,
        isLoadingComanda: false,
        comanda: action.payload.data.response,
      };
    }
    case "GET_COMANDA_REJECTED": {
      return {
        ...state,
        isLoadingComanda: false,
      };
    }
    case "UPDATE_COMANDA_PENDING": {
      return {
        ...state,
        isUpdatingComanda: true,
      };
    }
    case "UPDATE_COMANDA_FULFILLED": {
      return {
        ...state,
        isUpdatingComanda: false,
      };
    }
    case "DELETE_COMANDA_PENDING": {
      return {
        ...state,
        isUpdatingComanda: true,
      };
    }
    case "DELETE_COMANDA_FULFILLED": {
      return {
        ...state,
        isUpdatingComanda: false,
      };
    }
    case "DELETE_COMANDA_REJECTED": {
      return {
        ...state,
        isUpdatingComanda: false,
        //GESTIRE
      };
    }
    case "RESET_FORM_COMANDA": {
      return {
        ...state,
        comanda: null,
        isLoadingComanda: false,
        isLoadingComande: false,
      };
    }
    default:
      return state;
  }
};

export default reducer;
