import React, { Component } from "react";
import moment from "moment";

class ordinePrint extends Component {
  render() {
    const { ordine } = this.props;
    return (
      <div style={{ fontSize: "48px", padding: "20px" }}>
        {ordine ? (
          <>
            <div
              style={{
                textAlign: "center",
                fontWeight: "bolder",
                fontSize: "56px",
              }}
            >
              {ordine.cliente}
            </div>
            <div
              style={{
                textAlign: "center",
                marginBottom: "40px",
                fontSize: "52px",
              }}
            >
              {moment(ordine.date).format("HH:mm")}
            </div>
            {ordine.ordinePietanza.map((pietanza) => (
              <div
                style={{
                  padding: "25px 0",
                  borderBottom: "1px dotted #eaeaea",
                }}
                key={pietanza.id}
              >
                <p style={{ lineHeight: "55px", padding: "0", margin: "0" }}>
                  {pietanza.numero} x {pietanza.descrizione}
                  <br></br>
                  <span style={{ fontSize: "35px", whiteSpace: "pre-line" }}>
                    {pietanza.note}
                  </span>
                </p>
              </div>
            ))}
            {/* {ordine.note && (
              <div
                style={{
                  marginTop: "60px",
                  fontSize: "40px",
                }}
              >
                {ordine.note}
              </div>
            )} */}
          </>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default ordinePrint;
