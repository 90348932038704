import spring from "../../axios/axios"

const APIGET = "business"
const APIUPDATE = "updateBusiness"
const APIADD = "addBusiness"
const APIDELETE = "deleteBusiness"
const APISETIMAGE = "setImageToBusiness"
const APIDELETEIMAGE = "deleteImageToBusiness"

export const fetchBusiness = () => {
    return async dispatch => {
        return dispatch({
            type: 'FETCH_BUSINESS',
            payload: spring.get(APIGET)
        })
    }
}


export const getBusiness = data => {
    return async dispatch => {
        return dispatch({
            type: 'GET_BUSINESS_BY_ID',
            payload: spring.get(`${APIGET}/${data}`)
        })
    }
}


export const addBusiness = data => {
    return async dispatch => {
        return dispatch({
            type: 'ADD_BUSINESS',
            payload: spring.post(APIADD, {}, { params: data })
        })
    }
}

export const setBusinessImage = data => {
    return async dispatch => {
        return dispatch({
            type: 'UPDATE_BUSINESS',
            payload: spring.post(APISETIMAGE, data)
        })
    }
}

export const deleteBusinessImage = data => {
    return async dispatch => {
        return dispatch({
            type: 'UPDATE_BUSINESS',
            payload: spring.get(`${APIDELETEIMAGE}/${data}`)
        })
    }
}

export const updateBusiness = data => {
    return async dispatch => {
        return dispatch({
            type: 'UPDATE_BUSINESS',
            payload: spring.put(APIUPDATE, {}, { params: data })
        })
    }
}

export const deleteBusiness = data => {
    return async dispatch => {
        return dispatch({
            type: 'DELETE_BUSINESS',
            payload: spring.delete(`${APIDELETE}/${data}`)
        })
    }
}


export const resetForm = () => {
    return async dispatch => {
        return dispatch({
            type: 'RESET_FORM_BUSINESS'
        })
    }
}