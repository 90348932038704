import React, { Component } from 'react';
import { server } from "../config/server";

class Print extends Component {
    render() {

        const { menu } = this.props;
        let note = ""

        return (
            <div className="qrmenu2">
                {
                    menu.response &&
                    <>
                        {
                            !menu.response.business.extension ?
                                <div className="business">
                                    <h3>{menu.response.business.descrizione}<br></br><div>MENU</div></h3>
                                </div>
                                :
                                <div className="business-image"
                                    style={{
                                        backgroundImage: `url(${server}downloadBusinessImage?param=${menu.response.business.id}.${menu.response.business.extension}#${new Date().getTime()})`
                                    }}>
                                </div>
                        }
                        {
                            menu.response.pietanze.length === 0 ?
                                <div>Nessuna pietanza inserita</div>
                                :
                                menu.response.pietanze.map((pietanza, index) => {
                                    if (pietanza.id < 1) {
                                        note = pietanza.tipologia.note
                                    }
                                    return (
                                        <div key={index}>
                                            {
                                                pietanza.id < 1 ?
                                                    <div className={`tipologia ${pietanza.id < 0 ? "break-page" : ""}`}>
                                                        <h2>{pietanza.descrizione}<div></div></h2>
                                                    </div>
                                                    :
                                                    <div className="pietanza">
                                                        <div className="titolo">
                                                            <h3>{pietanza.descrizione}</h3>
                                                            <h3 className="prezzo">{pietanza.prezzo.toFixed(2)}<span>&nbsp;&euro;</span></h3>
                                                        </div>
                                                        <div className="materie_prime">
                                                            {
                                                                pietanza.materieprime.map((mp, index) => {
                                                                    return (
                                                                        <span key={index}>{mp.descrizione}{index < pietanza.materieprime.length - 1 ? " / " : ""}</span>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                            }
                                            {
                                                (index < menu.response.pietanze.length - 1 &&
                                                    menu.response.pietanze[index + 1].id < 0 && note !== null) ||
                                                    (index === menu.response.pietanze.length - 1 && menu.response.pietanze[index].id > 0 && note !== null) ?
                                                    <div className="note" dangerouslySetInnerHTML={{ __html: note.replace(/\n\r?/g, '<br/>') }} />
                                                    :
                                                    <></>
                                            }
                                        </div>
                                    )
                                })
                        }
                    </>
                }
            </div>
        );
    }
}

export default Print
