import React, { Component } from "react";
import PageHelmet from "../Components/Utils/PageHelmet";
import { getBusiness } from "../redux/actions/businessActions";
import {
  getMenuByBusiness,
  addPietanzeMenu,
  getPietanzaByMenuAndPietanza,
  resetForm,
  deletePietanzeFromMenu,
} from "../redux/actions/menuActions";
import { fetchTipologie } from "../redux/actions/tipologiaActions";
import {
  fetchPietanzeByBusinessAndTipologia,
  getPietanza,
  resetPietanzeTemp,
} from "../redux/actions/pietanzaActions";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import PietanzeTableDetails from "../Components/Pietanze/PietanzeTableDetails";
import { FiTrash, FiEdit2, FiPrinter, FiLink } from "react-icons/fi";
import LoadingData from "../Components/Utils/Datatable/LoadingData";
import { getPietanzeByDescrizione } from "../redux/actions/pietanzaActions";
import MenuForm from "../Components/Menu/MenuForm";
import memoize from "memoize-one";
import AnimateHeight from "react-animate-height";
import { store } from "react-notifications-component";
import alertOptions from "../Components/Alert/alertOptions";
import { confirmAlert } from "react-confirm-alert";
import Preview from "../Components/Menu/Preview";
import { GiWheat } from "react-icons/gi";
import { CgClose } from "react-icons/cg";
import { client } from "../config/client";
import { QRCode } from "react-qr-svg";
import { saveSvgAsPng } from "save-svg-as-png";
import ReactToPrint from "react-to-print";
import Print from "./Print";
import Select from "react-select";
import selectOptions from "../Components/Utils/Form/selectOptions";

const columns = memoize((actionHandler) => [
  {
    name: "Descrizione",
    selector: "descrizione",
    sortable: false,
    cell: (row) => (
      <>
        {row.id < 1 ? (
          <div className="row-group-table">{row.descrizione}</div>
        ) : (
          row.descrizione
        )}
      </>
    ),
  },
  {
    name: "Prezzo €",
    selector: "prezzo",
    width: "100px",
    right: true,
    sortable: false,
    cell: (row) => (
      <div style={{ fontWeight: "700" }}>{row.prezzo.toFixed(2)}</div>
    ),
  },
  {
    sortable: false,
    width: "100px",
    right: true,
    cell: (row) => (
      <div className="d-flex">
        <button
          className="btn btn-circle btn-sm btn-table warning"
          onClick={() => actionHandler("update", row.id)}
        >
          <FiEdit2 />
        </button>
        <button
          className="btn btn-circle btn-sm ml-1 btn-table danger"
          onClick={() => actionHandler("delete", row.id)}
        >
          <FiTrash />
        </button>
      </div>
    ),
  },
]);

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formOpened: false,
      costoTotale: 0,
      prezzoTotale: 0,
      isDisabled: false,
      tipologiaSel: null,
      pietanzaSel: null,
    };

    this.menuForm = React.createRef();
    this.select = React.createRef();
    this.blurActiveElement = this.blurActiveElement.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keypress", (e) => this.blurActiveElement(e));
    const { id } = this.props.match.params;
    this.props.getMenuByBusiness(id);
    this.props.fetchTipologie();
  }

  componentWillUnmount() {
    document.removeEventListener("keypress", (e) => this.blurActiveElement(e));
  }

  blurActiveElement = (e) => {
    if (e.keyCode === 13) {
      document.activeElement.blur();
    }
  };

  goBack = () => {
    this.props.history.push("/attivita");
  };

  renderPietanze = (inputValue, callback) => {
    if (!inputValue || inputValue.length < 3) {
      return callback([]);
    }
    this.props.getPietanzeByDescrizione(inputValue).then((res) => {
      return callback(res.value.data.response);
    });
  };

  handleSubmit = (data) => {
    const { menu } = this.props;
    const body = {
      id: menu.response.id,
      pietanza: data.pietanza.id,
      prezzo: data.prezzo,
      business: menu.response.business.id,
    };
    return this.props
      .addPietanzeMenu(body)
      .then((res) => {
        this.closeForm();
        store.addNotification({
          ...alertOptions,
          message: "Operazione effettuata",
          type: "success",
        });
      })
      .catch(() => {
        this.closeForm();
        store.addNotification({
          ...alertOptions,
          message: "Impossibile completare l'operazione",
          type: "danger",
        });
      });
  };

  actionHandler = (action, data) => {
    switch (action) {
      case "update":
        this.openForm();
        this.onGetPietanza(data);
        break;
      case "delete":
        this.onDelete(data);
        break;
      default:
        break;
    }
  };

  onGetPietanza = (data) => {
    const body = {
      menu: this.props.menu.response.id,
      pietanza: data,
    };
    this.props.getPietanzaByMenuAndPietanza(body).then((res) => {
      this.setState({
        costoTotale: res.value.data.response.costoTotale,
        prezzoTotale: res.value.data.response.prezzoTotale,
        isDisabled: true,
      });
    });
  };

  onDelete = (data) => {
    const body = {
      menu: this.props.menu.response.id,
      pietanza: data,
      business: this.props.menu.response.business.id,
    };
    confirmAlert({
      title: "Sei sicuro?",
      message: "Vuoi davvero elimiare l'elemento?",
      buttons: [
        {
          label: "Si",
          onClick: () => {
            this.props
              .deletePietanzeFromMenu(body)
              .then(() => {
                this.closeForm();
                store.addNotification({
                  ...alertOptions,
                  message: "Operazione effettuata",
                  type: "success",
                });
              })
              .catch(() => {
                store.addNotification({
                  ...alertOptions,
                  message: "Impossibile completare l'operazione",
                  type: "danger",
                });
              });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  openForm = () => {
    window.scrollTo(0, 0);
    this.setState({ formOpened: true });
  };

  closeForm = () => {
    this.props.resetPietanzeTemp();
    this.setState({
      formOpened: false,
      costoTotale: 0,
      prezzoTotale: 0,
      isDisabled: false,
    });
    this.resetSelect();
    this.menuForm.current.reset();
    this.props.resetForm();
  };

  onChangePietanza = (input, value) => {
    input.onChange(value);
    if (value) {
      this.setState({
        costoTotale: value.costoTotale,
        prezzoTotale: value.prezzoTotale,
      });
    } else {
      this.setState({
        costoTotale: 0,
        prezzoTotale: 0,
      });
    }
  };

  downloadCode = () => {
    const { menu } = this.props;
    saveSvgAsPng(
      document.getElementById("qrcode"),
      `${menu.response.business.descrizione} - QRCode.png`,
      { scale: 8, encoderOptions: 1, backgroundColor: "transparent" }
    );
  };

  cambiaTipologia = (value) => {
    this.setState({ tipologiaSel: value });
    if (value) {
      const data = {
        business: parseInt(this.props.match.params.id),
        tipologia: value.id,
      };
      this.props.fetchPietanzeByBusinessAndTipologia(data);
    } else {
      this.resetSelect();
      this.setState({ costoTotale: 0, prezzoTotale: 0 });
      this.props.resetPietanzeTemp();
      this.menuForm.current.reset();
      this.props.resetForm();
    }
  };

  cambiaPietanza = (value) => {
    this.setState({ pietanzaSel: value });
    if (value) {
      this.props.getPietanza(value.id).then((res) => {
        this.onChangePietanza(
          this.select.current.props,
          res.value.data.response
        );
      });
    } else {
      this.onChangePietanza(this.select.current.props, null);
    }
  };

  resetSelect = () => {
    this.setState({
      pietanzaSel: null,
      tipologiaSel: null,
    });
  };

  copyLink = () => {
    const copyText = document.getElementById("inputtocopy");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
    store.addNotification({
      ...alertOptions,
      message: "Link copiato negli appunti",
      type: "success",
    });
  };

  render() {
    const {
      menu,
      isLoadingBusiness,
      isLoadingMenu,
      pietanze,
      pietanza,
      isLoadingPietanza,
      isUpdatingPietanza,
      tipologie,
      pietanzeTemp,
    } = this.props;
    const {
      formOpened,
      costoTotale,
      prezzoTotale,
      isDisabled,
      tipologiaSel,
      pietanzaSel,
    } = this.state;

    return (
      <>
        <input
          type="text"
          id="inputtocopy"
          value={`${client}${this.props.match.params.id}`}
          style={{ position: "absolute", left: "-999px", top: "-999px" }}
        />
        <PageHelmet pageTitle="Attività" />
        <div className="ft-page-title">
          <div style={{ height: "fit-content" }}>
            {menu.response ? menu.response.business.descrizione : ""}
          </div>
          <button
            className="btn ft-btn-warning btn-sm ml-auto"
            onClick={() => this.goBack()}
          >
            Torna alle attività
          </button>
        </div>
        <AnimateHeight duration={350} height={formOpened ? "auto" : 0}>
          <div className="row mb-4">
            <div className="col col-12">
              <div className="card ft-box">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h6>
                      {pietanza.hasOwnProperty("pietanza")
                        ? "Modifica"
                        : "Aggiungi"}{" "}
                      pietanza
                    </h6>
                    <button
                      className="ft-btn-close-form btn btn-sm ft-btn-warning"
                      onClick={this.closeForm}
                    >
                      Chiudi
                    </button>
                  </div>
                  {(isLoadingPietanza || isUpdatingPietanza) && <LoadingData />}
                  <MenuForm
                    pietanze={pietanze}
                    addPietanza={this.addPietanza}
                    onSubmit={this.handleSubmit}
                    ref={this.menuForm}
                    renderPietanze={this.renderPietanze}
                    initialValues={pietanza}
                    onChangePietanza={this.onChangePietanza}
                    costoTotale={costoTotale}
                    prezzoTotale={prezzoTotale}
                    isDisabled={isDisabled}
                    select={this.select}
                  />
                  <h6>Pietanze non inserite nel menu</h6>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label>Tipologie</label>
                        <Select
                          options={tipologie.response}
                          styles={selectOptions}
                          getOptionValue={(option) => option.id}
                          getOptionLabel={(option) => option.descrizione}
                          noOptionsMessage={(value) => "Nessun risultato"}
                          placeholder="Seleziona tipologia"
                          onChange={(value) => this.cambiaTipologia(value)}
                          isClearable
                          value={tipologiaSel}
                          isDisabled={isDisabled}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label>Pietanze</label>
                        <Select
                          options={
                            pietanzeTemp.response ? pietanzeTemp.response : []
                          }
                          styles={selectOptions}
                          getOptionValue={(option) => option.id}
                          getOptionLabel={(option) => option.descrizione}
                          noOptionsMessage={(value) => "Nessun risultato"}
                          placeholder="Seleziona tipologia"
                          onChange={(value) => this.cambiaPietanza(value)}
                          isClearable
                          value={pietanzaSel}
                          isDisabled={isDisabled}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AnimateHeight>
        <div className="row">
          <div className="col col-12">
            <div className="card ft-box">
              <div className="card-body">
                <div className="title-copy">
                  <QRCode
                    id="qrcode"
                    bgColor="transparent"
                    fgColor="#000000"
                    level="Q"
                    style={{ width: 1024, display: "none" }}
                    value={`${client}${this.props.match.params.id}`}
                  />
                  <h6>Menu</h6>
                  <div className="copy-path">
                    <ReactToPrint
                      trigger={() => {
                        return (
                          <button className="btn btn-sm ft-btn-secondary mr-1">
                            <FiPrinter />
                          </button>
                        );
                      }}
                      content={() => this.componentRef}
                    />
                    <button
                      className="btn btn-sm ft-btn-warning mr-1"
                      onClick={this.copyLink}
                    >
                      <FiLink />
                    </button>
                    <button
                      className="btn btn-sm ft-btn-primary"
                      onClick={this.downloadCode}
                    >
                      Download QRCode
                    </button>
                  </div>
                </div>
                {menu.response && menu.response.pietanze.length === 0 && (
                  <>
                    <div>Nessuna pietanza inserita</div>
                  </>
                )}
                <button
                  className="btn ft-btn-secondary btn-sm mt-2 mb-2"
                  onClick={this.openForm}
                  disabled={formOpened}
                >
                  Aggiungi pietanza
                </button>
                {(isLoadingBusiness || isLoadingMenu) && <LoadingData />}
                {menu.response && menu.response.pietanze.length > 0 && (
                  <DataTable
                    className="ft-table"
                    columns={columns(this.actionHandler)}
                    data={menu.response.pietanze}
                    expandableRows
                    expandableRowsComponent={<PietanzeTableDetails />}
                    expandableIcon={{
                      collapsed: <GiWheat className="primary" />,
                      expanded: (
                        <CgClose
                          className="primary"
                          style={{ height: "46px" }}
                        />
                      ),
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div style={{ display: "none" }}>
          <Print menu={menu} ref={(el) => (this.componentRef = el)} />
        </div>
        <Preview menu={menu} />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoadingBusiness: state.business.isLoadingBusiness,
    business: state.business.business,
    menu: state.menu.menu,
    isLoadingMenu: state.menu.isLoadingMenu,
    pietanze: state.pietanze.pietanze,
    pietanza: state.menu.pietanza,
    isLoadingPietanza: state.menu.isLoadingPietanza,
    isUpdatingPietanza: state.menu.isUpdatingPietanza,
    tipologie: state.tipologie.tipologie,
    isLoadingTipologie: state.tipologie.isLoadingTipologie,
    pietanzeTemp: state.pietanze.pietanzeTemp,
    isLoadingPietanzeTemp: state.pietanze.isLoadingPietanzeTemp,
  };
};

export default connect(mapStateToProps, {
  resetPietanzeTemp,
  getPietanza,
  fetchPietanzeByBusinessAndTipologia,
  fetchTipologie,
  getBusiness,
  getMenuByBusiness,
  getPietanzeByDescrizione,
  addPietanzeMenu,
  getPietanzaByMenuAndPietanza,
  deletePietanzeFromMenu,
  resetForm,
})(Menu);
