const validate = (values) => {
    const errors = {};
    if (!values.pietanza) {
        errors.pietanza = {
            message: 'Pietanza richiesta'
        }
    }
    if (!values.prezzo) {
        errors.prezzo = {
            message: 'Prezzo richiesto'
        }
    } else if (!/^[0-9]+(\.[0-9]{1,3})?$/.test(values.prezzo)) {
        errors.prezzo = {
            message: 'Prezzo non valido'
        }
    }
    return errors;
}

export default validate;