import spring from "../../axios/axios"


export const userLogin = data => {
    return dispatch => {
        return dispatch({
            type: 'USER_LOGIN',
            payload: spring.post('login', {}, {params:data})
        })
    }
};

export const checkUser= () => {
    return dispatch => {
        return dispatch({
            type: 'CHECK_USER',
            payload: spring.get('checkUser')
        })
    }
};