import { combineReducers } from "redux";
import userReducer from "./userReducer";
import sidebarReducer from "./sidebar_reducer";
import materiePrime from "./materiaPrimaReducer";
import tipologiaReducer from "./tipologiaReducer";
import pietanzaReducer from "./pietanzaReducer";
import businessReducer from "./businessReducer";
import menuReducer from "./menuReducer";
import comandaReducer from "./comandaReducer";
import ordineReducer from "./ordineReducer";
import { reducer as formReducer } from "redux-form";

export default combineReducers({
  sidebar: sidebarReducer,
  user: userReducer,
  materiePrime: materiePrime,
  tipologie: tipologiaReducer,
  pietanze: pietanzaReducer,
  business: businessReducer,
  menu: menuReducer,
  comanda: comandaReducer,
  ordine: ordineReducer,
  form: formReducer,
});
