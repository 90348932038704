let initialState = {
    user: null,
    isAuth: false,
    message: "",
    isLoading:false
}

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case 'USER_LOGIN_PENDING':
            {
                return {
                    ...state,
                    isLoading:true   
                }
            }

        case 'USER_LOGIN_FULFILLED':
            {
                localStorage.setItem("jwt", action.payload.headers.jwt)
                return {
                    ...state,
                    user: action.payload.data.response,
                    isAuth: true,
                    isLoading:false 
                }
            }
        case 'USER_LOGIN_REJECTED':
            {
                return {
                    user: null,
                    isAuth: false,
                    message: action.payload.response.data.response,
                    isLoading:false
                }
            }
        case 'CHECK_USER_PENDING':
            {
                return {
                    ...state,
                    isLoading:true
                }
            }    
        case 'CHECK_USER_FULFILLED':
            {
                return {
                    ...state,
                    user: action.payload.data.response,
                    isAuth: true,
                    isLoading:false 
                }
            }
        case 'CHECK_USER_REJECTED':
            {
                return {
                    user: null,
                    isAuth: false,
                    message: action.payload.response.data.response,
                    isLoading:false
                }
            }
        default:
            return state;
    }
}

export default reducer