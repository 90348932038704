const initialState = {
    menu:{},
    isLoadingMenu:false,
    isLoadingPietanza: false,
    isUpdatingPietanza: false,
    pietanza:{}
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_MENU_BY_BUSINESS_PENDING': {
            return {
                ...state,
                isLoadingMenu: true,
            }
        }
        case 'GET_MENU_BY_BUSINESS_FULFILLED': {
            return {
                ...state,
                isLoadingMenu: false,
                menu: action.payload.data,
            }
        }
        case 'ADD_PIETANZA_MENU_PENDING': {
            return {
                ...state,
                isLoadingMenu: true,
                isUpdatingPietanza:true
            }
        }
        case 'ADD_PIETANZA_MENU_FULFILLED': {
            return {
                ...state,
                isLoadingMenu: false,
                isUpdatingPietanza:true,
                menu: action.payload.data,
            }
        }
        case 'DELETE_PIETANZA_MENU_PENDING': {
            return {
                ...state,
                isLoadingMenu: true,
                isUpdatingPietanza:true
            }
        }
        case 'DELETE_PIETANZA_MENU_FULFILLED': {
            return {
                ...state,
                isLoadingMenu: false,
                isUpdatingPietanza:true,
                menu: action.payload.data,
            }
        }
        case 'GET_PIETANZA_MENU_PENDING': {
            return {
                ...state,
                isLoadingPietanza: true,
            }
        }
        case 'GET_PIETANZA_MENU_FULFILLED': {
            const response = {
                pietanza: action.payload.data.response,
                prezzo: action.payload.data.response.prezzo
            }
            return {
                ...state,
                isLoadingPietanza: false,
                pietanza: response,
            }
        }
        case 'RESET_FORM_MENU': {
            return {
                ...state,
                pietanza: {},
                isLoadingPietanza:false,
                isUpdatingPietanza:false,
            }
        }
        default:
            return state;
    }
}

export default reducer;