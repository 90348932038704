import React from 'react';
import { Field, reduxForm } from 'redux-form';
import validate from "./validateMenu";
import AsyncSelect from 'react-select/async';
import selectOptions from "../Utils/Form/selectOptions";

const renderField = ({
    input,
    label,
    type,
    costoTotale,
    prezzoTotale,
    meta: { touched, error }
}) => (
    <div className="form-group">
        <label>
            {label}
            {
                (costoTotale > 0 && prezzoTotale > 0) &&
                <span className="ml-1 costi">(Costo: {costoTotale} - Prezzo: {prezzoTotale})</span>
            }
        </label>
        <input {...input} type={type} className={`ft-form-control form-control ${touched && error && 'ft-input-error'}`} placeholder={label} />
        {
            touched && error &&
            <small className="form-text error">{error.message}</small>
        }
    </div>
)


const ReduxFormSelectAsync = props => {
    const { input, label, render, getOptionValue, getOptionLabel, onChangePietanza, isDisabled, riferimento } = props;
    const { touched, error } = props.meta



    return (
        <div className="mb-3">
            <label>{label}</label>
            <AsyncSelect
                {...input}
                classNamePrefix={touched && error && 'hasError'}
                onChange={value => onChangePietanza(input, value)}
                isClearable
                cacheOptions
                onBlur={() => input.onBlur(input.value)}
                loadOptions={render}
                styles={selectOptions}
                placeholder={label}
                noOptionsMessage={(value) => value.inputValue.length < 3 ? "Inserisci almeno 3 caratteri" : "Nessun risultato"}
                loadingMessage={() => "Cerco..."}
                getOptionValue={getOptionValue}
                getOptionLabel={getOptionLabel}
                isDisabled={isDisabled}
                ref={riferimento}
            />
            {
                touched && error &&
                <small className="form-text error">{error.message}</small>
            }
        </div>

    )
}

const MenuForm = props => {


    const { select, handleSubmit, selectOptions, renderPietanze, addPietanza, pristine, submitting, onChangePietanza, costoTotale, prezzoTotale, isDisabled } = props;


    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col col-12">
                    <Field
                        name="pietanza"
                        label="Pietanza"
                        component={ReduxFormSelectAsync}
                        options={selectOptions}
                        render={renderPietanze}
                        addPietanza={addPietanza}
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) => option.descrizione}
                        onChangePietanza={onChangePietanza}
                        isDisabled={isDisabled}
                        riferimento={select}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col col-12">
                    <Field name="prezzo" type="text" component={renderField} label="Prezzo" costoTotale={costoTotale} prezzoTotale={prezzoTotale} />
                </div>
            </div>
            <div className="row mb-2">
                <div className="col col-12 d-flex justify-content-end">
                    <button type="submit" className="ft-btn-close-form btn btn-sm ft-btn-secondary" disabled={pristine || submitting}>Salva</button>
                </div>
            </div>
        </form >
    )
}

export default reduxForm({
    form: 'menu-form',
    validate,
    enableReinitialize: true,
    destroyOnUnmount: false,
})(MenuForm)