import React, { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import Login from './Views/Login';
import { checkUser } from "./redux/actions/userActions";
import App from './App';


const PrivateRoute = ({ component: Component, isAuth, ...rest }) => (
    <Route {...rest} render={props => (
        isAuth ? (
            <Component {...props} />
        ) : (
            <Redirect to={{
                pathname: '/login',
                state: { from: props.location }
            }} />
        )
    )} />
)

class MainApp extends Component {


    componentDidMount() {
        this.props.checkUser().then().catch(() => { })
    }

    render() {
        const { isAuth } = this.props

        return (

            <Switch>
                <Route path="/login/" component={Login} />
                <PrivateRoute component={App} isAuth={isAuth} />
            </Switch>
        )
    }
}

const mapStateToProps = state => {

    return {
        isAuth: state.user.isAuth,
        message: state.user.message
    }
}

export default connect(mapStateToProps, { checkUser })(MainApp)
