import React from 'react';
import { AiOutlineLoading } from 'react-icons/ai';

const LoadingData = () => {
    return (
        <div className="ft-table-form-loading"><AiOutlineLoading/></div> 
    )
}

export default LoadingData
