import React, { Component } from "react";
import PageHelmet from "../Components/Utils/PageHelmet";
import { connect } from "react-redux";
import {
  fetchTipologie,
  changeOrderTipologie,
  resetForm,
  getTipologia,
  updateTipologia,
  addTipologia,
  deleteTipologia,
} from "../redux/actions/tipologiaActions";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import LoadingData from "../Components/Utils/Datatable/LoadingData";
import { store } from "react-notifications-component";
import alertOptions from "../Components/Alert/alertOptions";
import { FiPlus, FiTrash, FiEdit2 } from "react-icons/fi";
import TipologieForm from "../Components/Tipologie/TipologieForm";
import AnimateHeight from "react-animate-height";
import { confirmAlert } from "react-confirm-alert";
import isEqual from "react-fast-compare";

class Tipologie extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tipologie: {},
      formOpened: false,
      isDeleting: false,
    };

    this.tipologiaForm = React.createRef();
    this.blurActiveElement = this.blurActiveElement.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keypress", (e) => this.blurActiveElement(e));
    this.getTipologie();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let state = {};
    if (!isEqual(nextProps.tipologie, prevState.tipologie)) {
      state.tipologie = nextProps.tipologie;
    }
    return state;
  }

  componentWillUnmount() {
    document.removeEventListener("keypress", (e) => this.blurActiveElement(e));
  }

  blurActiveElement = (e) => {
    if (e.keyCode === 13) {
      if (document.activeElement.tagName.toLowerCase() !== "input") {
        document.getElementById("note").focus();
      }
    }
  };

  getTipologie = () => {
    this.props.fetchTipologie().then(() => {
      this.setState({ tipologie: this.props.tipologie });
    });
  };

  dragEnd = (data) => {
    if (
      data.destination === null ||
      data.destination.index === data.source.index
    )
      return;
    const { tipologie } = this.props;
    const start = tipologie.response[data.source.index].ordine;
    const end = tipologie.response[data.destination.index].ordine;
    this.props
      .changeOrderTipologie(start, end)
      .then(() => {
        this.updateTipologieState(data);
      })
      .catch(() => {
        store.addNotification({
          ...alertOptions,
          message: "Impossibile completare l'operazione",
          type: "danger",
        });
      });
  };

  updateTipologieState = (data) => {
    const { tipologie } = this.state;
    const items = Array.from(tipologie.response);
    const [reorderedItem] = items.splice(data.source.index, 1);
    items.splice(data.destination.index, 0, reorderedItem);
    const obj = { server: tipologie.server, response: items };
    this.setState({ tipologie: obj });
  };

  handleSubmit = (data) => {
    const { tipologia } = this.props;
    if (!tipologia.hasOwnProperty("id")) {
      return this.props
        .addTipologia(data)
        .then((res) => {
          this.actionsCallback();
          store.addNotification({
            ...alertOptions,
            message: "Operazione effettuata",
            type: "success",
          });
        })
        .catch(() => {
          this.actionsCallback();
          store.addNotification({
            ...alertOptions,
            message: "Impossibile completare l'operazione",
            type: "danger",
          });
        });
    }
    data.id = tipologia.id;
    return this.props
      .updateTipologia(data)
      .then(() => {
        this.actionsCallback();
        store.addNotification({
          ...alertOptions,
          message: "Operazione effettuata",
          type: "success",
        });
      })
      .catch(() => {
        this.actionsCallback();
        store.addNotification({
          ...alertOptions,
          message: "Impossibile completare l'operazione",
          type: "danger",
        });
      });
  };

  actionsCallback = () => {
    this.closeForm();
  };

  openForm = () => {
    window.scrollTo(0, 0);
    this.setState({ formOpened: true });
    setTimeout(() => {
      document.getElementsByName("descrizione")[0].focus();
    }, 500);
  };

  closeForm = () => {
    this.setState({ formOpened: false });
    this.tipologiaForm.current.reset();
    this.props.resetForm();
  };

  onDelete = (data) => {
    this.setState({ isDeleting: false });
    confirmAlert({
      title: "Sei sicuro?",
      message: "Vuoi davvero elimiare l'elemento?",
      buttons: [
        {
          label: "Si",
          onClick: () => {
            this.props
              .deleteTipologia(data)
              .then(() => {
                this.actionsCallback();
                store.addNotification({
                  ...alertOptions,
                  message: "Operazione effettuata",
                  type: "success",
                });
              })
              .catch(() => {
                store.addNotification({
                  ...alertOptions,
                  message: "Impossibile completare l'operazione",
                  type: "danger",
                });
              });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  getTipologia = (data) => {
    this.openForm();
    this.setState({ isDeleting: false });
    this.onGetTipologia(data);
  };

  onGetTipologia = (data) => {
    this.props.getTipologia(data);
  };

  render() {
    const {
      isLoadingTipologie,
      isLoadingTipologia,
      isUpdatingTipologia,
      tipologia,
    } = this.props;
    const { tipologie, formOpened } = this.state;

    return (
      <>
        <PageHelmet pageTitle="Tipologie" />
        <div className="ft-page-title">
          <div>Tipologie</div>
        </div>
        <AnimateHeight duration={350} height={formOpened ? "auto" : 0}>
          <div className="row mb-4">
            <div className="col col-12">
              <div className="card ft-box">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h6>
                      {tipologia.hasOwnProperty("id") ? "Modifica" : "Aggiungi"}{" "}
                      tipologia
                    </h6>
                    <button
                      className="ft-btn-close-form btn btn-sm ft-btn-warning"
                      onClick={this.closeForm}
                    >
                      Chiudi
                    </button>
                  </div>
                  {(isLoadingTipologia || isUpdatingTipologia) && (
                    <LoadingData />
                  )}
                  <TipologieForm
                    onSubmit={this.handleSubmit}
                    ref={this.tipologiaForm}
                    initialValues={tipologia}
                  />
                </div>
              </div>
            </div>
          </div>
        </AnimateHeight>
        <div className="row">
          <div className="col col-12">
            <div className="card ft-box">
              <div className="card-body">
                {isLoadingTipologie ? (
                  <LoadingData />
                ) : (
                  <>
                    <div className="d-flex justify-content-end mt-2 mb-4">
                      <button
                        className="ft-btn-add-element btn ft-btn-primary btn-circle"
                        onClick={this.openForm}
                      >
                        <FiPlus />
                      </button>
                    </div>
                    {tipologie.response && tipologie.response.length > 0 ? (
                      <DragDropContext onDragEnd={this.dragEnd}>
                        <Droppable droppableId="tipologie">
                          {(provided) => (
                            <ul
                              className="tipologie"
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {tipologie.response.map(
                                ({ id, descrizione }, index) => {
                                  return (
                                    <Draggable
                                      key={id}
                                      draggableId={id.toString()}
                                      index={index}
                                    >
                                      {(provided) => (
                                        <li
                                          className="draggable-item"
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          ref={provided.innerRef}
                                        >
                                          <div className="d-flex align-items-center">
                                            <div>{descrizione}</div>
                                            <div className="ml-auto">
                                              <div className="d-flex align-items-center">
                                                <button
                                                  id={id}
                                                  className="btn btn-circle btn-table warning btn-sm"
                                                  onClick={() =>
                                                    this.getTipologia(id)
                                                  }
                                                >
                                                  <FiEdit2 />
                                                </button>
                                                <button
                                                  id={id}
                                                  className="btn btn-circle btn-table danger btn-sm ml-1"
                                                  onClick={() =>
                                                    this.onDelete(id)
                                                  }
                                                >
                                                  <FiTrash />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                      )}
                                    </Draggable>
                                  );
                                }
                              )}
                              {provided.placeholder}
                            </ul>
                          )}
                        </Droppable>
                      </DragDropContext>
                    ) : (
                      <div>Nessuna tipologia inserita</div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoadingTipologie: state.tipologie.isLoadingTipologie,
    tipologie: state.tipologie.tipologie,
    tipologia: state.tipologie.tipologia,
    isLoadingTipologia: state.tipologie.isLoadingTipologia,
    isDeletingTipologia: state.tipologie.isDeletingTipologia,
    isUpdatingTipologia: state.tipologie.isUpdatingTipologia,
  };
};

export default connect(mapStateToProps, {
  fetchTipologie,
  changeOrderTipologie,
  resetForm,
  getTipologia,
  updateTipologia,
  addTipologia,
  deleteTipologia,
})(Tipologie);
