import React, { Component } from 'react';
import { connect } from "react-redux";

import { toggleSidebarMobile } from "../../../redux/actions/sidebar_actions";

class MainOverlay extends Component {

    onToggleSidebarMobile = () => {
        this.props.toggleSidebarMobile();
    }

    render() {
        return (
            <div className="ft-main-overlay" onClick={this.onToggleSidebarMobile}></div>
        )
    }
}

export default connect(null, { toggleSidebarMobile })(MainOverlay);
